import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNewStock } from "../../../Redux/Actions/StockAction/StockAction";
import NewStockView from "./NewStockView";
import isEmpty from "../../../Utils/isEmpty/isEmpty";
import { NOT_FOUND_BARCODE } from "../../../Constant/primitive";
import {
  PriceCalAction,
  StockAction,
  StockSelector,
} from "../../../Redux/Reducers/Slices";
import { SPECIAL_CHAR } from "../../../Constant/regexConstant";

const NewStockContainer = () => {
  const dispatch = useDispatch();
  const [barcodeId, setBarcodeID] = useState({ barCodeId: "" });
  const [barcodeError, setBarcodeError] = useState({});
  const [isStatusInput, setIsStatusInput] = useState(false);
  const { newStockInfo, supplierDetails } = useSelector(StockSelector);

  const handleBarcodeChange = (e) => {
    const { value } = e.target;
    const newVal = value?.replace(SPECIAL_CHAR, "");
    setBarcodeError({});
    setBarcodeID({ barCodeId: newVal });
  };

  const NewStockData = async (barcodeId) => {
    setIsStatusInput(true);
    const response = await dispatch(getNewStock(barcodeId));
    setIsStatusInput(false);
    if (response?.status === 404) {
      dispatch(StockAction?.getNewStockByBarcodeId({}));
      setBarcodeError({ ...barcodeError, barCodeId: NOT_FOUND_BARCODE });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isEmpty(barcodeId?.barCodeId)) {
      NewStockData(barcodeId?.barCodeId);
      dispatch(PriceCalAction?.priceCalculationInfo({}));
      dispatch(PriceCalAction?.priceCalculationErrors({}));
      dispatch(StockAction?.supplierDetails({}));
    }
  };

  useEffect(() => {
    dispatch(StockAction?.getNewStockByBarcodeId({}));
  }, []);

  return (
    <NewStockView
      {...{
        supplierDetails,
        handleKeyDown,
        handleBarcodeChange,
        barcodeId,
        isStatusInput,
        barcodeError,
        newStockInfo,
        setBarcodeID,
      }}
    />
  );
};

export default NewStockContainer;
