import React, { useEffect, useRef, useState } from "react";
import TransactionButtonView from "./TransactionButtonView";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderHistoryData,
  getOrderHistoryProducts,
  getPosSetting,
  sendMailToCustomer,
} from "../../../Redux/Actions";
import { getTotalTaxValue, isEmpty } from "../../../Utils";
import {
  posAction,
  posSelector,
  settingSelector,
} from "../../../Redux/Reducers/Slices";
import { useReactToPrint } from "react-to-print";
import { calculateTotal } from "../../../Utils/PriceCalculation/calculateCash";
import { useMutation } from "@tanstack/react-query";
import { COUNTRY_LIST_PHONE_CODE } from "../../../Constant/CountryList";
import { PEOPLE_EMAIL_REGEX } from "../../../Constant/regexConstant";
import {
  saleAction,
  saleSelector,
} from "../../../Redux/Reducers/SaleReducer/SaleReducer";
import { getPosTotalWholeSalePrice } from "../../../Utils/PriceCalculation/PosPriceCalculation";

const TransactionButtonContainer = () => {
  const [totalCash, setTotalCash] = useState();
  const [transactionModal, setTransactionModal] = useState({
    isOpen: false,
  });
  const { subTotal } = useSelector(saleSelector);
  const [sucessPaymentDetails, setSucessPaymentDetails] = useState({});
  const [transactionModalData, setTransactionModalData] = useState({
    isLoading: false,
    page: 1,
    limit: 10,
    role: "admin",
    status: "complete",
    searchKeyword: "",
  });
  const [viewUserProductModal, setViewUserProductModal] = useState({
    isOpen: false,
    viewProductData: {},
  });
  const [viewUserReceipt, setViewUserReceipt] = useState({
    isOpen: false,
    viewCustomerReceipt: {},
  });
  const [sendMail, setSendMail] = useState({
    isSendMail: false,
    sendMailData: {},
  });
  const [error, setError] = useState("");
  const componentRef = useRef(null);
  const dispatch = useDispatch();
  const { posOrderHistoryInfo, productsTaxTotal } = useSelector(posSelector);
  const { systemSettingDetails, posReceiptSetting } =
    useSelector(settingSelector);
  const getTransactionInfo = async (page, limit, searchValue) => {
    let transactionData = { ...transactionModalData };
    transactionData = {
      ...transactionModalData,
      isLoading: true,
      searchKeyword: searchValue,
      limit: limit,
    };
    const payload = {
      transactionType: 0,
      isCurrentDay: true,
      ...(!isEmpty(searchValue) && { searchKeyword: searchValue }),
    };
    await dispatch(
      getOrderHistoryData(
        page,
        limit,
        transactionData?.role,
        transactionData?.status,
        payload
      )
    );
    transactionData = { ...transactionData, isLoading: false };
    setTransactionModalData(transactionData);
  };

  const handleOpenModal = async () => {
    setTransactionModal({ ...transactionModal, isOpen: true });
    setTransactionModalData({ ...transactionModalData, isLoading: true });
    getTransactionInfo(
      transactionModalData?.page,
      transactionModalData?.limit,
      transactionModalData?.searchKeyword
    );
  };

  const handleCloseModal = () => {
    setTransactionModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleModalOpenViewUserProduct = async (record) => {
    setViewUserProductModal({
      ...viewUserProductModal,
      isOpen: true,
      viewProductData: record,
    });
    setTransactionModal({ ...transactionModal, isOpen: true });
    await dispatch(
      getOrderHistoryProducts("admin", "completed", record?.referenceId)
    );
  };

  const handleCloseUserProductView = () => {
    setViewUserProductModal({ ...viewUserProductModal, isOpen: false });
  };

  const handlePrint = (record) => {
    setViewUserReceipt({
      ...viewUserReceipt,
      isOpen: true,
      viewCustomerReceipt: record,
    });
    const billNumber = record?.transactionTables[0]?.billNumber;
    const createdAt = record?.transactionTables[0]?.createdAt;
    setSucessPaymentDetails({
      ...sucessPaymentDetails,
      billNumber: billNumber,
      createdAt: createdAt,
    });
    const CashTotal = JSON.parse(record?.transactionTables[0]?.cashQuantity);
    setTotalCash({
      ...totalCash,
      CashTotal: calculateTotal(CashTotal),
    });
  };

  const handlePrintModelClose = () => {
    setViewUserReceipt({
      ...viewUserReceipt,
      isOpen: false,
      viewCustomerReceipt: {},
    });
    setSucessPaymentDetails({});
    setTotalCash();
  };

  const handleGetPosSetting = async () => {
    const response = await dispatch(getPosSetting());
    return response;
  };

  useEffect(() => {
    handleGetPosSetting();
  }, []);

  const handlePrintReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleRemainingAmount = (grandTotal, cash, bank) => {
    return Number(cash) + Number(bank) - Number(grandTotal);
  };

  //mail-send
  const handleSubmitReceiptLinkMail = async ({ payload }) => {
    const response = await dispatch(sendMailToCustomer(payload));
    return response;
  };

  const handleSuccessMutation = (response) => {
    if (response?.status === 200) {
      setSendMail({
        ...sendMail,
        isSendMail: false,
        sendMailData: {},
      });
    }
  };

  const { mutate, isPending: isSendMailLoading } = useMutation({
    mutationFn: handleSubmitReceiptLinkMail,
    onSuccess: handleSuccessMutation,
  });

  const handleConfirmSendMail = () => {
    if (
      !sendMail?.sendMailData?.customerInfo?.emailId?.match(PEOPLE_EMAIL_REGEX)
    ) {
      setError("Invalid Email");
    } else {
      setError("");
      const obj = { ...sendMail?.sendMailData };
      delete obj?.customerInfo?.customerType;
      const data = { payload: obj };
      mutate(data);
    }
  };

  const handleSendMailReceiptLink = (transactionRecord) => {
    const cashTotal = calculateTotal(
      JSON.parse(transactionRecord?.transactionTables[0]?.cashQuantity)
    );
    const price = handleRemainingAmount(
      transactionRecord?.transactionTables[0]?.grandTotal,
      cashTotal,
      JSON.parse(transactionRecord?.transactionTables[0]?.bankTransfer)
        ?.amount || "0.00"
    );
    const countryCode = COUNTRY_LIST_PHONE_CODE?.find(
      (ele) => ele?.isoCode === transactionRecord?.CustomerModel?.countryCode
    );
    const payload = {
      name: "Retail",
      smsMessage:
        "Dear Sir/Madam, Thank you for shopping at rothwell. As part of our green initiative, your digital bill awaits",
      customerInfo: {
        customerName: transactionRecord?.CustomerModel?.customerName,
        customerId: transactionRecord?.CustomerModel?.customerId,
        phoneNo: `${isEmpty(countryCode?.code) ? "" : countryCode?.code}${transactionRecord?.CustomerModel?.phoneNo}`,
        emailId:
          transactionRecord?.CustomerModel?.customerType === "system"
            ? ""
            : transactionRecord?.CustomerModel?.emailId,
        customerType: transactionRecord?.CustomerModel?.customerType,
      },
      companyInfo: {
        companyName: systemSettingDetails?.companyName,
        address: systemSettingDetails?.address,
        emailId: systemSettingDetails?.emailId,
        companyPhoneNumber: systemSettingDetails?.companyPhoneNumber,
        telephoneNo: systemSettingDetails?.telephoneNo,
        bankName: systemSettingDetails?.bankName,
        BankIFSCCode: systemSettingDetails?.BankIFSCCode,
        accountNumber: systemSettingDetails?.accountNumber,
        websiteLogo: systemSettingDetails?.websiteLogo,
      },
      payment: {
        subTotal: transactionRecord?.transactionTables?.[0]?.subTotal,
        taxPrice:
          getTotalTaxValue(transactionRecord?.productSolds, "price") || 0,
        discountPrice: transactionRecord?.transactionTables?.[0]?.discountPrice,
        grandTotal: transactionRecord?.transactionTables?.[0]?.grandTotal,
        totalCash: calculateTotal(
          JSON.parse(transactionRecord?.transactionTables[0]?.cashQuantity)
        ),
        changeSubTotal: price > 0 ? parseFloat(price).toFixed(2) : 0,
        bankTransfer: JSON.parse(
          transactionRecord?.transactionTables?.[0]?.bankTransfer
        ),
      },
      products: transactionRecord?.productSolds?.map((product) => {
        const { productName, quantity, price, subtotal } = product;
        const productObj = { productName, quantity, price, subtotal };
        return productObj;
      }),
      systemSettingDetails: {
        currency: systemSettingDetails?.currency,
        vatNo: systemSettingDetails?.vatNo,
      },
      posReceiptSetting,
      billNumber: transactionRecord?.transactionTables[0]?.billNumber,
      createdAt: transactionRecord?.transactionTables[0]?.createdAt,
      referenceNumber: transactionRecord?.referenceNumber,
    };
    setSendMail({
      ...sendMail,
      isSendMail: true,
      sendMailData: payload,
    });
  };

  const handleCloseConfirmModel = () => {
    setSendMail({
      ...sendMail,
      isSendMail: false,
      sendMailData: {},
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setSendMail({
      ...sendMail,
      sendMailData: {
        ...sendMail?.sendMailData,
        customerInfo: {
          ...sendMail?.sendMailData?.customerInfo,
          [name]: value,
        },
      },
    });
    setError("");
  };

  useEffect(() => {
    const subTotalPrice = getPosTotalWholeSalePrice(
      viewUserReceipt?.viewCustomerReceipt?.productSolds
    );
    const subTotalTax = getTotalTaxValue(
      viewUserReceipt?.viewCustomerReceipt?.productSolds,
      viewUserReceipt?.viewCustomerReceipt?.transactionTables?.[0]
        ?.transactionType === 0
        ? "price"
        : "wholeSalePrice"
    );
    dispatch(posAction.taxTotal(subTotalTax));
    dispatch(saleAction.subTotal(subTotalPrice));
  }, [viewUserReceipt?.viewCustomerReceipt?.productSolds]);

  return (
    <TransactionButtonView
      {...{
        sendMail,
        isSendMailLoading,
        error,
        posOrderHistoryInfo,
        transactionModal,
        viewUserProductModal,
        transactionModalData,
        setViewUserProductModal,
        setTransactionModalData,
        setTransactionModal,
        handleOpenModal,
        handleCloseModal,
        handleModalOpenViewUserProduct,
        handleCloseUserProductView,
        getTransactionInfo,
        handlePrint,
        viewUserReceipt,
        sucessPaymentDetails,
        handlePrintReceipt,
        componentRef,
        totalCash,
        handleRemainingAmount,
        handlePrintModelClose,
        handleSendMailReceiptLink,
        handleCloseConfirmModel,
        handleConfirmSendMail,
        handleChange,
        productsTaxTotal,
        subTotal,
      }}
    />
  );
};

export default TransactionButtonContainer;
