import React from "react";
import {
  AuthContainer,
  BackupListContainer,
  BrandContainer,
  CategoryContainer,
  CreateProductsContainer,
  CurrencyListContainer,
  CustomerListContainer,
  DashboardContainer,
  ExpensesListContainer,
  ForgotPasswordContainer,
  GroupSettingsContainer,
  NewExpensesContainer,
  NewGroupPermissionContainer,
  NewPurchaseContainer,
  NewPurchaseReturnContainer,
  NewQuotationContainer,
  NewSalesContainer,
  NewSalesReturnContainer,
  NewStockContainer,
  PosContainer,
  ProductDetailsContainer,
  ProductsListContainer,
  ProfileContainer,
  PurchaseListContainer,
  PurchaseReturnListContainer,
  QuotationListContainer,
  SalesListContainer,
  SalesReturnListContainer,
  StockDetailsContainer,
  StockListContainer,
  SupplierListContainer,
  SystemSettingsContainer,
  UnitListContainer,
  UserListContainer,
  VegetableFruitsContainer,
  VegetablesFruitsCategoryContainer,
  VegetablesFruitsViewContainer,
  CreateDiscountsContainer,
  DiscountListContainer,
  CreateDiscountProductContainer,
  DiscountListProductContainer,
  PurchaseReportContainer,
  PurchasePaymentReportContainer,
  PurchaseReturnReportContainer,
  ProductReportContainer,
  SaleReportContainer,
  SalePaymentReportContainer,
  MixMatchCreateContainer,
  MixMatchTableContainer,
  MixMatchDetailContainer,
  SaleReturnReportContainer,
  UserReportContainer,
  SupplierReportContainer,
  CustomerReportContainer,
  StockReportContainer,
  MixMatchUpdateContainer,
  TopProductContainer,
  SupplierProductListContainer,
  ZReportContainer,
  DepartmentContainer,
  StockEvaluationReportContainer,
  BundleItemDiscountContainer,
  BundleItemDiscountListContainer,
  UserTransactionListContainer,
  ExpensesReportContainer,
  TillListContainer,
} from "../pages";
import isTokenValid from "./Token";
import { Navigate } from "react-router-dom";
import Custom404 from "../CommonComponent/Custom404/Custom404";
import { useDispatch } from "react-redux";
import {
  VEGETABLE_FRUITS_LIST,
  ADMIN_LOG_IN,
  BACKUP,
  BRAND_LIST,
  CATEGORY_LIST,
  CERATE_PURCHASE,
  CERATE_QUOTATION,
  CERATE_SALE,
  CREATE_EXPENSES,
  CREATE_PRODUCT,
  CREATE_PURCHASE_RETURN,
  CREATE_SALES_RETURN,
  CURRENCY_LIST,
  CUSTOMER_LIST,
  DASHBOARD,
  EDIT_PRODUCTS_WITH_ID,
  EDIT_PURCHASE_RETURN,
  EDIT_SALE_RETURN,
  EXPENSES_LIST,
  FORGOT_PASSWORD,
  GROUP_PERMISSIONS,
  LOG_IN,
  MY_PROFILE,
  NEW_GROUP_PERMISSION,
  NEW_STOCK,
  POS,
  PRODUCT_LIST,
  PRODUCT_ROUTE_WITH_ID,
  PURCHASE_LIST,
  PURCHASE_RETURN_LIST,
  QUOTATION_LIST,
  SALES_RETURN_LIST,
  SALE_LIST,
  SUPPLIER_LIST,
  SYSTEM_SETTINGS,
  UNIT_LIST,
  USER_LIST,
  STOCK_LIST,
  VEGETABLE_FRUITS_NEW,
  VEGETABLE_VIEW_FRUITS_WITH_ID,
  VEGETABLE_EDIT_FRUITS_WITH_ID,
  STOCK_VIEW,
  EDIT_SALE_WITH_ID,
  EDIT_QUOTATION_WITH_ID,
  EDIT_PURCHASE_WITH_ID,
  CREATE_DISCOUNT_VEGETABLE_FRUIT,
  DISCOUNT_LIST_VEG_FRUIT,
  EDIT_DISCOUNT_VEGETABLE_FRUIT_WITH_ID,
  CREATE_DISCOUNT_PRODUCT,
  DISCOUNT_LIST_PRODUCT,
  EDIT_DISCOUNT_PRODUCT_WITH_ID,
  PURCHASE_REPORT,
  PURCHASE_PAYMENT_REPORT,
  PURCHASE_RETURN_REPORT,
  PRODUCT_REPORT,
  SALES_REPORT,
  SALES_PAYMENT_REPORT,
  MIX_MATCH_CREATE,
  MIX_MATCH_LIST,
  MIX_MATCH_LIST_ID,
  SALES_RETURN_REPORT,
  USER_REPORT,
  SUPPLIER_REPORT,
  CUSTOMER_REPORT,
  STOCK_REPORT,
  MIX_MATCH_LIST_UPDATE,
  TOP_PRODUCT_REPORT,
  SUPPLIER_PRODUCT_LIST,
  EDIT_WITH_ID_GROUP_PERMISSION,
  Z_REPORT,
  EDIT_EXPENSES_WITH_ID,
  DEPARTMENT_LIST,
  STOCK_VALUATION_REPORT,
  BUNDLE_ITEM_DISCOUNT_CREATE,
  BUNDLE_ITEM_DISCOUNT_LIST,
  EDIT_BUNDLE_ITEM_DISCOUNT_WITH_ID,
  USER_TRANSACTION_LIST,
  EXPENSES_REPORT,
  TILE_LIST,
} from "../Constant/routeConstant";

const AllRoutes = () => {
  const dispatch = useDispatch();
  const TOKEN = dispatch(isTokenValid());
  const routesPath = [
    {
      path: LOG_IN,
      exact: true,
      element: !TOKEN ? (
        <AuthContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: ADMIN_LOG_IN,
      exact: true,
      element: !TOKEN ? (
        <AuthContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: FORGOT_PASSWORD,
      exact: true,
      element: !TOKEN ? (
        <ForgotPasswordContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: DASHBOARD,
      exact: true,
      element: TOKEN ? (
        <DashboardContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: VEGETABLE_FRUITS_NEW,
      exact: true,
      element: TOKEN ? (
        <VegetableFruitsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: VEGETABLE_EDIT_FRUITS_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <VegetableFruitsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: VEGETABLE_VIEW_FRUITS_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <VegetablesFruitsViewContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: VEGETABLE_FRUITS_LIST,
      exact: true,
      element: TOKEN ? (
        <VegetablesFruitsCategoryContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CREATE_PRODUCT,
      exact: true,
      element: TOKEN ? (
        <CreateProductsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: NEW_STOCK,
      exact: true,
      element: TOKEN ? (
        <NewStockContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SUPPLIER_PRODUCT_LIST,
      exact: true,
      element: TOKEN ? (
        <SupplierProductListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: STOCK_LIST,
      exact: true,
      element: TOKEN ? (
        <StockListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: STOCK_VIEW,
      exact: true,
      element: TOKEN ? (
        <StockDetailsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PRODUCT_ROUTE_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <ProductDetailsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_PRODUCTS_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <CreateProductsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PRODUCT_LIST,
      exact: true,
      element: TOKEN ? (
        <ProductsListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CREATE_EXPENSES,
      exact: true,
      element: TOKEN ? (
        <NewExpensesContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_EXPENSES_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <NewExpensesContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EXPENSES_LIST,
      exact: true,
      element: TOKEN ? (
        <ExpensesListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CERATE_QUOTATION,
      exact: true,
      element: TOKEN ? (
        <NewQuotationContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_QUOTATION_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <NewQuotationContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: QUOTATION_LIST,
      exact: true,
      element: TOKEN ? (
        <QuotationListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CERATE_PURCHASE,
      exact: true,
      element: TOKEN ? (
        <NewPurchaseContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_PURCHASE_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <NewPurchaseContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PURCHASE_LIST,
      exact: true,
      element: TOKEN ? (
        <PurchaseListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CERATE_SALE,
      exact: true,
      element: TOKEN ? (
        <NewSalesContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_SALE_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <NewSalesContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SALE_LIST,
      exact: true,
      element: TOKEN ? (
        <SalesListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CREATE_SALES_RETURN,
      exact: true,
      element: TOKEN ? (
        <NewSalesReturnContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SALES_RETURN_LIST,
      exact: true,
      element: TOKEN ? (
        <SalesReturnListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_SALE_RETURN,
      exact: true,
      element: TOKEN ? (
        <SalesReturnListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: POS,
      exact: true,
      element: TOKEN ? (
        <PosContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CREATE_PURCHASE_RETURN,
      exact: true,
      element: TOKEN ? (
        <NewPurchaseReturnContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PURCHASE_RETURN_LIST,
      exact: true,
      element: TOKEN ? (
        <PurchaseReturnListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_PURCHASE_RETURN,
      exact: true,
      element: TOKEN ? (
        <PurchaseReturnListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CUSTOMER_LIST,
      exact: true,
      element: TOKEN ? (
        <CustomerListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SUPPLIER_LIST,
      exact: true,
      element: TOKEN ? (
        <SupplierListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: USER_LIST,
      exact: true,
      element: TOKEN ? (
        <UserListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: USER_TRANSACTION_LIST,
      exact: true,
      element: TOKEN ? (
        <UserTransactionListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: TILE_LIST,
      exact: true,
      element: TOKEN ? (
        <TillListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SYSTEM_SETTINGS,
      exact: true,
      element: TOKEN ? (
        <SystemSettingsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: GROUP_PERMISSIONS,
      exact: true,
      element: TOKEN ? (
        <GroupSettingsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CATEGORY_LIST,
      exact: true,
      element: TOKEN ? (
        <CategoryContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: BRAND_LIST,
      exact: true,
      element: TOKEN ? (
        <BrandContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: DEPARTMENT_LIST,
      exact: true,
      element: TOKEN ? (
        <DepartmentContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: NEW_GROUP_PERMISSION,
      exact: true,
      element: TOKEN ? (
        <NewGroupPermissionContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_WITH_ID_GROUP_PERMISSION,
      exact: true,
      element: TOKEN ? (
        <NewGroupPermissionContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CURRENCY_LIST,
      exact: true,
      element: TOKEN ? (
        <CurrencyListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: UNIT_LIST,
      exact: true,
      element: TOKEN ? (
        <UnitListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: BACKUP,
      exact: true,
      element: TOKEN ? (
        <BackupListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: MY_PROFILE,
      exact: true,
      element: TOKEN ? (
        <ProfileContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CREATE_DISCOUNT_VEGETABLE_FRUIT,
      exact: true,
      element: TOKEN ? (
        <CreateDiscountsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_DISCOUNT_PRODUCT_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <CreateDiscountProductContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CREATE_DISCOUNT_PRODUCT,
      exact: true,
      element: TOKEN ? (
        <CreateDiscountProductContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: DISCOUNT_LIST_PRODUCT,
      exact: true,
      element: TOKEN ? (
        <DiscountListProductContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_DISCOUNT_VEGETABLE_FRUIT_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <CreateDiscountsContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: DISCOUNT_LIST_VEG_FRUIT,
      exact: true,
      element: TOKEN ? (
        <DiscountListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PURCHASE_REPORT,
      exact: true,
      element: TOKEN ? (
        <PurchaseReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PURCHASE_PAYMENT_REPORT,
      exact: true,
      element: TOKEN ? (
        <PurchasePaymentReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PURCHASE_RETURN_REPORT,
      exact: true,
      element: TOKEN ? (
        <PurchaseReturnReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: PRODUCT_REPORT,
      exact: true,
      element: TOKEN ? (
        <ProductReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SALES_REPORT,
      exact: true,
      element: TOKEN ? (
        <SaleReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SALES_PAYMENT_REPORT,
      exact: true,
      element: TOKEN ? (
        <SalePaymentReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SALES_RETURN_REPORT,
      exact: true,
      element: TOKEN ? (
        <SaleReturnReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: STOCK_REPORT,
      exact: true,
      element: TOKEN ? (
        <StockReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: SUPPLIER_REPORT,
      exact: true,
      element: TOKEN ? (
        <SupplierReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: CUSTOMER_REPORT,
      exact: true,
      element: TOKEN ? (
        <CustomerReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: USER_REPORT,
      exact: true,
      element: TOKEN ? (
        <UserReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: TOP_PRODUCT_REPORT,
      exact: true,
      element: TOKEN ? (
        <TopProductContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: Z_REPORT,
      exact: true,
      element: TOKEN ? (
        <ZReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: STOCK_VALUATION_REPORT,
      exact: true,
      element: TOKEN ? (
        <StockEvaluationReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EXPENSES_REPORT,
      exact: true,
      element: TOKEN ? (
        <ExpensesReportContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: MIX_MATCH_CREATE,
      exact: true,
      element: TOKEN ? (
        <MixMatchCreateContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: MIX_MATCH_LIST,
      exact: true,
      element: TOKEN ? (
        <MixMatchTableContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: MIX_MATCH_LIST_ID,
      exact: true,
      element: TOKEN ? (
        <MixMatchDetailContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: MIX_MATCH_LIST_UPDATE,
      exact: true,
      element: TOKEN ? (
        <MixMatchUpdateContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: BUNDLE_ITEM_DISCOUNT_CREATE,
      exact: true,
      element: TOKEN ? (
        <BundleItemDiscountContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: EDIT_BUNDLE_ITEM_DISCOUNT_WITH_ID,
      exact: true,
      element: TOKEN ? (
        <BundleItemDiscountContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: BUNDLE_ITEM_DISCOUNT_LIST,
      exact: true,
      element: TOKEN ? (
        <BundleItemDiscountListContainer />
      ) : (
        <Navigate to={LOG_IN} replace={true} />
      ),
    },
    {
      path: "*",
      element: <Custom404 />,
    },
  ];
  return routesPath;
};

export default AllRoutes;
