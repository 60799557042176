import { Col, Row } from "antd";
import React from "react";
import { POS_INPUT_FIELDS } from "../../../Constant/non-primitive";
import {
  ButtonComponent,
  FormFieldsComponent,
  ImageComponent,
} from "../../../CommonComponent";
import "../ReferenceForm/referenceForm.scss";
import CreateCustomerModal from "../../Model/CreateCustomerModal/CreateCustomerModal";
import { customerAdd } from "../../../assest";
import { LoadingOutlined } from "@ant-design/icons";

const ReferenceFormView = ({
  isLoading,
  values,
  ReferenceNumber,
  customerData,
  handleFormChange,
  handleSearchChange,
  searchValue,
  handleSearchKeyDown,
  // handleRedeem,
  error,
  isModelOpen,
  customerDetails,
  countryList,
  customerError,
  phoneMaxLength,
  tableData,
  handleOpenCreateCustomerModel,
  handleModalCancel,
  handleSearchCountry,
  handleSelectChange,
  handleInputChange,
  handleBlur,
  isCustomerAddLoading,
  handleSubmitCustomerInfo,
}) => {
  const customerDataList = customerData
    ?.filter((ele) => ele?.customerType !== "WholeSale")
    ?.map((val) => {
      return {
        value: val.customerId,
        label: val?.customerName,
      };
    });
  return (
    <Row gutter={[15, 0]} className="pos-list-details-main">
      {POS_INPUT_FIELDS?.map((ele) => (
        <Col
          key={ele?.name}
          span={24}
          xxl={ele?.name === "customerCode" || ele?.name === "points" ? 9 : 10}
          xl={ele?.name === "customerCode" || ele?.name === "points" ? 9 : 10}
          lg={ele?.name === "customerCode" || ele?.name === "points" ? 9 : 10}
          md={12}
          sm={12}
          xs={24}
          className="pos-list-details-input"
        >
          <FormFieldsComponent
            name={ele?.name}
            type={ele?.type}
            error={ele?.name === "points" && error}
            defaultValue={values[ele?.name]}
            options={customerData?.length > 0 && [...customerDataList]}
            handleChange={handleFormChange}
            handleKeyDown={handleSearchKeyDown}
            label={ele?.label}
            value={
              ele?.name === "ReferenceNumber"
                ? ReferenceNumber
                : values[ele?.name]
            }
            handleBlur={() => {}}
            handleSelectChange={handleFormChange}
            searchValue={searchValue}
            handleSearchChange={handleSearchChange}
            disabled={
              ele?.name === "ReferenceNumber" ||
              (ele?.name === "points" && true)
            }
            placeholder={ele?.placeHolder}
            prefix={ele?.prefix}
            isSearch={ele?.isSearch}
            inputClass={"reference-number-input"}
            inputMain={"reference-number-input-main"}
            labelClass={"reference-number-labels"}
            SelectClassNames={"reference-number-dropdown"}
            showSearch={false}
            inputIcon={
              ele?.name === "customerCode" &&
              isLoading && (
                <div className="code-loader">
                  <LoadingOutlined />
                </div>
              )
            }
          />
        </Col>
      ))}
      <Col
        span={6}
        xxl={4}
        xl={4}
        lg={4}
        md={3}
        sm={3}
        className="redeem-btn pos-list-details-input"
      >
        <ButtonComponent
          btnIcon={
            <ImageComponent
              imageSrc={customerAdd}
              imageAlt={"add-customer"}
              imageClassName={"add-customer"}
              imageHeight={19}
              imageWidth={19}
            />
          }
          isFrontIcon={true}
          handleClick={handleOpenCreateCustomerModel}
          btnClass={"add-customer-btn"}
        />
      </Col>
      {/* <Col
        span={6}
        xxl={6}
        xl={6}
        lg={6}
        md={4}
        sm={4}
        className="redeem-btn pos-list-details-input"
      >
        <ButtonComponent
          btnName={"Redeem"}
          handleClick={handleRedeem}
          btnClass={"filter-button"}
        />
      </Col> */}
      {isModelOpen && (
        <CreateCustomerModal
          {...{
            countryList,
            phoneMaxLength,
            tableData,
            customerData: customerDetails,
            isCustomerAdd: isCustomerAddLoading,
            isModalOpen: isModelOpen,
            customerError,
            handleModalCancel,
            handleSearchCountry,
            handleSelectChange,
            handleInputChange,
            handleBlur,
            handleSubmitCustomerInfo,
          }}
        />
      )}
    </Row>
  );
};

export default ReferenceFormView;
