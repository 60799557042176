import React from "react";
import { VegetablesFruitsCategoryTable } from "../../../Component";
import { DeleteModalComponent } from "../../../Component/Model";
import "./vegetablesFruitsList.scss";

const VegetablesFruitsCategoryView = ({
  handleAddNewVegetablesFruitsCategory,
  handleDeleteModal,
  handleEditModal,
  isDeleteModal,
  handleCancelDeleteRecord,
  handleSaveDeleteRecord,
  vegFruitListInfo,
  isVegFruitListLoading,
  handleViewModal,
  myPermissions,
  handlePageChange,
  total,
  limit,
  handleSearchChange,
  currentPage,
  handleVegFruitListSort,
  brandData,
  loading,
  handleSelectChange,
  handleFilterSearch,
  filterSearch,
  handleKeyDown,
  showSuggestionList,
  handleFocusSearchInput,
  suggestionList,
  suggestionListLoading,
  setShowSuggestionList,
  getSearchedProduct,
  listRef,
}) => {
  return (
    <div className="Vegetables-main">
      <VegetablesFruitsCategoryTable
        {...{
          handleVegFruitListSort,
          handleAddNewVegetablesFruitsCategory,
          handleDeleteModal,
          handleEditModal,
          vegFruitListInfo,
          isVegFruitListLoading,
          handleViewModal,
          myPermissions,
          handlePageChange,
          total,
          limit,
          handleSearchChange,
          currentPage,
          brandData,
          loading,
          handleSelectChange,
          handleFilterSearch,
          filterSearch,
          handleKeyDown,
          isSuggestionListVisible: true,
          showSuggestionList,
          handleFocusSearchInput,
          suggestionList,
          suggestionListLoading,
          setShowSuggestionList,
          getSearchedProduct,
          listRef,
        }}
      />
      {isDeleteModal?.isOpen && (
        <DeleteModalComponent
          {...{
            isModalOpen: isDeleteModal?.isOpen,
            handleCancelDeleteRecord,
            isDeleteModalLoading: isDeleteModal?.isLoading,
            handleSaveDeleteRecord,
            name: `Fruit or Vegetable`,
          }}
        />
      )}
    </div>
  );
};

export default VegetablesFruitsCategoryView;
