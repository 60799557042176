import React, { useMemo } from "react";
import { ButtonComponent, ModalComponent } from "../../../CommonComponent";
import WholeSalePrintReceipt from "../../Sale/WholeSalePrintReceipt";

const PaymentModalView = ({
  paymentModal,
  // handleNextOrder,
  // isStatus,
  handlePrint,
  grandTotal,
  discountTotal,
  mixMatchDiscountTotal,
  customerId,
  customerData,
  productToCart,
  componentRef,
  paymentSuccessDetails,
  productsTaxTotal,
  systemSettingDetails,
  subTotal,
}) => {
  const userData = useMemo(() => {
    return customerData.find((res) => res?.customerId === customerId);
  }, [customerData]);

  return (
    <ModalComponent
      modalOpen={paymentModal?.isOpen}
      modalTitle={""}
      closeIcon={false}
      modalWidth={780}
      modalClass={"Payment-completed-method"}
      footer={
        <div className="payment-complete-btn">
          <ButtonComponent
            btnName={"Print Receipt"}
            btnClass={"print-receipt"}
            handleClick={handlePrint}
          />
        </div>
      }
    >
      <div className="payment-complete-modal-main">
        {paymentModal?.isPrintReceipt && (
          <WholeSalePrintReceipt
            {...{
              name: "Retail",
              componentRef,
              productToCart,
              systemSettingDetails,
              grandTotal,
              customerRecord: userData,
              transactionData: paymentSuccessDetails,
              productsTaxTotal,
              subTotal,
              discountTotal:
                discountTotal || mixMatchDiscountTotal
                  ? Number(discountTotal) + Number(mixMatchDiscountTotal)
                  : "0.00",
            }}
          />
        )}
      </div>
    </ModalComponent>
  );
};

export default PaymentModalView;
