import React from "react";
import { PRODUCT_LIST_COLUMN } from "../../../Constant/TableConst";
import "../Products-List/productslist.scss";
import { TableContainer } from "../../../CommonComponent";
import { DeleteModalComponent } from "../../../Component/Model";

const ProductsListView = ({
  handleChangeNewProduct,
  handleDeleteProduct,
  productData,
  handlePageChange,
  currentPage,
  total,
  limit,
  handleChangeLimit,
  deleteModal,
  handleDeleteConfirmModal,
  handleDeleteCancelModal,
  isStatus,
  loading,
  handleSearchChange,
  handleKeyDown,
  handleViewProducts,
  handleEditProducts,
  myPermissions,
  handleSelectChange,
  brandData,
  handleFilterSearch,
  filterSearch,
  handleProductListSort,
  showSuggestionList,
  handleFocusSearchInput,
  suggestionList,
  suggestionListLoading,
  setShowSuggestionList,
  getSearchedProduct,
  listRef,
}) => {
  return (
    <div className="product-list-main">
      <TableContainer
        {...{
          loading,
          limit,
          brandData,
          handleChangeLimit,
          isPagination: true,
          isTableHeader: true,
          column: PRODUCT_LIST_COLUMN(
            handleViewProducts,
            handleEditProducts,
            handleDeleteProduct,
            myPermissions
          ),
          handleSort: handleProductListSort,
          isFilterBtn: true,
          dataSource: productData,
          btnTitle: myPermissions?.allAllowed && "Products",
          handleClickAddNewFunctionality: handleChangeNewProduct,
          handlePageChange,
          isSuggestionListVisible: true,
          showSuggestionList,
          setShowSuggestionList,
          suggestionListLoading,
          handleFocusSearchInput,
          getSearchedProduct,
          suggestionList,
          listRef,
          currentPage,
          total,
          handleSearchChange,
          handleKeyDown,
          handleSelectChange,
          fieldsOptions: brandData,
          handleFilterSearch,
          filterValue: filterSearch,
          isTableSearch: true,
        }}
        classNames={"product-list-table"}
      />
      {deleteModal?.isDeleteModal && (
        <DeleteModalComponent
          {...{
            isModalOpen: deleteModal?.isDeleteModal,
            handleCancelDeleteRecord: handleDeleteCancelModal,
            isDeleteModalLoading: isStatus,
            handleSaveDeleteRecord: handleDeleteConfirmModal,
            name: `product`,
          }}
        />
      )}
    </div>
  );
};

export default ProductsListView;
