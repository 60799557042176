import React from "react";
import CustomerSearchView from "./CustomerSearchView";

const CustomerSearchContainer = ({
  inputMain,
  SelectClassNames,
  error,
  placeholder,
  // searchList,
  options,
  handleGetCustomerData,
  handleFormChange,
  handleChange,
  value,
  handleSelectChange,
}) => {
  return (
    <CustomerSearchView
      {...{
        inputMain,
        SelectClassNames,
        error,
        placeholder,
        handleChange,
        options,
        handleGetCustomerData,
        handleFormChange,
        value,
        handleSelectChange,
      }}
    />
  );
};

export default CustomerSearchContainer;
