import React from "react";
import {
  ButtonComponent,
  ImageUploadComponent,
  LottieImage,
} from "../../../CommonComponent";
import { Col, Row } from "antd";
import "./vegetableFruit.scss";
import {
  VegetablesFruitsDetailsContainer,
  VegetablesFruitsPackageContainer,
  VegetablesFruitsPriceContainer,
  VegetablesFruitsProductContainer,
} from "../../../Component";
import { isEmpty } from "../../../Utils";
import { LoadingOutlined } from "@ant-design/icons";
import { loader } from "../../../assest";

const VegetableFruitsView = ({
  handleSubmit,
  vegFruitProductInfo,
  handleImageChange,
  handleRemoveImage,
  vegFruitImage,
  id,
  isProfileUpdate,
}) => {
  return (
    <div>
      {id && isEmpty(vegFruitProductInfo) ? (
        <LottieImage lottieImage={loader} imageClassName="loader" />
      ) : (
        <>
          <form className="veg-and-fruits-wrap">
            <Row gutter={[20, 24]}>
              <Col span={24} xxl={17} xl={17} lg={17} md={24} sm={24}>
                <VegetablesFruitsProductContainer />
                {(vegFruitProductInfo?.productType === "2" ||
                  vegFruitProductInfo?.productType === "0") && (
                  <VegetablesFruitsPriceContainer />
                )}
                {(vegFruitProductInfo?.productType == "1" ||
                  vegFruitProductInfo?.productType == "0") && (
                  <VegetablesFruitsPackageContainer />
                )}
                <VegetablesFruitsDetailsContainer />
              </Col>
              <Col span={24} xxl={7} xl={7} lg={7} md={24} sm={24}>
                <div className="multiple-image">
                  <h1 className="multiple-image-title">
                    Add Fruits & Veg./Bulk-items Image
                  </h1>
                  <div className="multiple-images-main">
                    <React.Fragment>
                      <ImageUploadComponent
                        {...{
                          type: "file",
                          name: "image",
                          label: "Add Multiple Image",
                          error: vegFruitImage?.error,
                          handleImageChange,
                          handleRemoveImage,
                        }}
                        image={
                          vegFruitImage?.image?.length > 0 && id
                            ? vegFruitImage?.image
                            : isEmpty(vegFruitImage?.image)
                              ? ""
                              : typeof vegFruitImage?.image === "string"
                                ? vegFruitImage?.image
                                : URL.createObjectURL(vegFruitImage?.image)
                        }
                        uploadImage={"profile-uploaded-image"}
                      />
                    </React.Fragment>
                    {}
                  </div>
                </div>
              </Col>
            </Row>
          </form>
          <div className="btn-fixed">

          <ButtonComponent
            btnName={isProfileUpdate ? <LoadingOutlined /> : "Save"}
            btnClass="submit-products-btn"
            btnType={"submit"}
            btnDisabled={isProfileUpdate && true}
            handleClick={handleSubmit}
          />
          </div>
        </>
      )}
    </div>
  );
};

export default VegetableFruitsView;
