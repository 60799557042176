import React, { useState } from "react";
import AuthView from "./AuthView";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signInUser } from "../../Redux/Actions";
import { DASHBOARD } from "../../Constant/routeConstant";
import { AUTH_VALUES, LOGIN_INPUT_FIELDS } from "../../FormSchema/LoginSchema";
import { validation } from "../../Utils";
import { EMAIL_REGEX } from "../../Constant/regexConstant";

const AuthContainer = () => {
  const [authError, setAuthError] = useState({ auth: "" });
  const [authValues, setAuthValues] = useState(AUTH_VALUES);
  const [isPasswordShow, setIsPasswordShow] = useState({});
  const [isStatus, setIsStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAuthInputChange = (e) => {
    const { value, name } = e.target;
    const NewObj = value?.trim();
    const authObj = {
      ...authValues,
      [name]: NewObj,
    };

    setAuthValues(authObj);
    setAuthError({
      ...authError,
      auth: "",
    });
  };

  const handleIsShowPassword = (name) => {
    setIsPasswordShow({
      ...isPasswordShow,
      [name]: true,
    });
  };

  const handleAuthBlur = (name) => {
    const { errors } = validation(
      name,
      authValues[name],
      authError,
      LOGIN_INPUT_FIELDS[name]
    );
    setAuthError(errors);
  };

  const handleIsShowPasswordInvisible = (name) => {
    setIsPasswordShow({
      ...isPasswordShow,
      [name]: false,
    });
  };

  const handleOnSubmitForm = async (e) => {
    e.preventDefault();
    if (
      Object.values(authError).every((value) => !value) &&
      Object.values(authValues).every((value) => value)
    ) {
      setIsStatus(true);
      const isEmail = authValues?.["login name"]?.match(EMAIL_REGEX);
      const name = isEmail ? "emailId" : "userName";
      const authPayload = {
        [name]: authValues?.["login name"],
        password: authValues?.password,
      };
      const res = await dispatch(signInUser(authPayload));
      setIsStatus(false);
      switch (res?.status) {
        case 401:
          setAuthError({
            ...authError,
            auth: "Invalid credentials",
          });
          break;
        case 200:
          navigate(DASHBOARD);
          window.location.reload();
          break;
        case 404:
          setAuthError({
            ...authError,
            auth: "User not found",
          });
          break;
        default:
          setAuthError({
            ...authError,
            auth: res?.data?.message,
          });
      }
    }
  };

  const handleAuthKeyDown = (e) => {
    if (e.key === "Enter") {
      handleOnSubmitForm(e);
    }
  };

  return (
    <AuthView
      {...{
        handleOnSubmitForm,
        isStatus,
        handleChange: handleAuthInputChange,
        authError,
        isPasswordShow,
        handleIsShowPasswordInvisible,
        handleIsShowPassword,
        handleAuthKeyDown,
        authValues,
        handleAuthBlur,
      }}
    />
  );
};

export default AuthContainer;
