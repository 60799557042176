import React from "react";
import "./stockList.scss";
import { TableContainer } from "../../../CommonComponent";
import { STOCK_LIST_COLUMN } from "../../../Constant/TableConst";
import {
  DeleteModalComponent,
  EditStockModalComponent,
} from "../../../Component/Model";

const StockListView = ({
  filterValueJson,
  stockHistory,
  limit,
  currentPage,
  total,
  handlePageChange,
  isLoading,
  isEditPrice,
  setIsEditPrice,
  handleSearchChange,
  handleChangeRoute,
  handleViewStockDetails,
  handleEditStockOpenModal,
  editModalOpen,
  handleEditStockCloseModal,
  editStockDetails,
  formFieldData,
  handleChange,
  handleBlur,
  handleSelectChange,
  formFieldDataPacked,
  handleUpdateStockDetails,
  isStockPending,
  editStockErrors,
  isDeleteModal,
  handleCancelDeleteRecord,
  handleSaveDeleteRecord,
  handleOpenDeleteModal,
  handleSelectFilter,
  handleFilterSave,
  btnDisabled,
  setBtnDisabled,
  myPermissions,
}) => {
  return (
    <div className="stock-list-main">
      <TableContainer
        {...{
          loading: isLoading,
          currentPage,
          limit,
          total,
          handleBlur: () => {},
          // setShowSuggestionList: () => {},
          handlePageChange,
          handleSearchChange,
          column: STOCK_LIST_COLUMN(
            handleViewStockDetails,
            handleEditStockOpenModal,
            handleOpenDeleteModal,
            myPermissions
          ),
          handleChange: handleSelectFilter,
          dataSource: stockHistory,
          isPagination: true,
          isTableHeader: true,
          btnTitle: myPermissions?.["D-003"]?.["P-004"] && "Stock",
          handleClickAddNewFunctionality: handleChangeRoute,
          isTableSearch: true,
          isFilterBtn: true,
          isStockList: true,
          handleFilterSearch: handleFilterSave,
          filterValue: filterValueJson,
        }}
        classNames={"stock-list-table"}
      />
      {editModalOpen && (
        <EditStockModalComponent
          {...{
            editModalOpen,
            handleEditStockCloseModal,
            formFieldData,
            handleChange,
            handleBlur,
            handleSelectChange,
            editStockDetails,
            formFieldDataPacked,
            handleUpdateStockDetails,
            isStockPending,
            editStockErrors,
            isEditPrice,
            setIsEditPrice,
            btnDisabled,
            setBtnDisabled,
          }}
        />
      )}
      {isDeleteModal?.isOpen && (
        <DeleteModalComponent
          {...{
            isModalOpen: isDeleteModal?.isOpen,
            handleCancelDeleteRecord,
            handleSaveDeleteRecord,
            isDeleteModalLoading: isDeleteModal?.isLoading,
            name: `Stock`,
          }}
        />
      )}
    </div>
  );
};

export default StockListView;
