import React from "react";
import {
  FormFieldsComponent,
  ImageComponent,
  LottieImage,
  ProductCardComponent,
} from "../../../CommonComponent";
import { loader, noDataFound, searchIcon } from "../../../assest";
import { Col, Pagination, Row } from "antd";
import "../Product/product.scss";
import { ProductPackedSelectModalContainer } from "../../Model";
import { POS_FILTER_OPTIONS } from "../../../Constant/non-primitive";
import SuggestionListContainer from "../../../CommonComponent/SuggestionList/SuggestionListContainer";

const ProductView = ({
  currentPage,
  total,
  limit,
  productData,
  productValueObj,
  handleProductClick,
  handleSearchChange,
  handleKeyDown,
  searchValue,
  filterDropDownValue,
  handleSelectChange,
  systemSettingDetails,
  handlePageChange,
  showSuggestionList,
  setShowSuggestionList,
  suggestionListLoading,
  handleFocusSearchInput,
  getSearchedProduct,
  suggestionList,
  listRef,
}) => {
  return (
    <React.Fragment>
      <div className="category-main">
        <div ref={listRef} className="category-input-main">
          <FormFieldsComponent
            name={"type"}
            type={"select"}
            placeholder={"Search product by code"}
            value={filterDropDownValue}
            options={POS_FILTER_OPTIONS}
            handleSelectChange={handleSelectChange}
            handleBlur={() => {}}
          />
          <div className="pos-search-product">
            <FormFieldsComponent
              name={"Product"}
              type={"text"}
              placeholder={"Search product by code"}
              inputClass={"choose-product-search"}
              inputMain={"input-main"}
              prefix={
                <ImageComponent
                  imageSrc={searchIcon}
                  imageAlt={"search-icon"}
                  imageClassName={"search-icon"}
                />
              }
              value={searchValue}
              handleBlur={() => {}}
              handleOnFocus={handleFocusSearchInput}
              handleChange={handleSearchChange}
              handleKeyDown={handleKeyDown}
            />
            <SuggestionListContainer
              {...{
                listRef,
                showSuggestionList,
                suggestionList,
                suggestionListLoading,
                setShowSuggestionList,
                getSearchedProduct,
              }}
            />
          </div>
        </div>
        {!productValueObj?.isLoading && productData?.length > 0 ? (
          <div className="pos-product-cards-main">
            <Row gutter={[15, 15]} className="products-main">
              {productData?.map((productObj) => (
                <Col
                  span={6}
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={6}
                  sm={8}
                  xs={24}
                  key={productObj?.productId}
                >
                  <ProductCardComponent
                    {...{
                      handleProductClick,
                      productObj,
                      systemSettingDetails,
                    }}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ) : productValueObj?.isLoading ? (
          <LottieImage
            lottieImage={loader}
            lottieText={""}
            divClassName={"loader-animation-main"}
            imageClassName={"loader-animation"}
          />
        ) : (
          <div className="no-data-main">
            <LottieImage
              lottieImage={noDataFound}
              lottieText={"No Product found"}
              divClassName={"page-not-found-main"}
              textClassName={"not-found-text"}
              imageClassName={"page-not-found-icon"}
            />
          </div>
        )}
      </div>
      <Pagination
        current={currentPage}
        pageSize={limit}
        total={total}
        showSizeChanger={true}
        onChange={handlePageChange}
        className="pagination"
      />
      <ProductPackedSelectModalContainer />
    </React.Fragment>
  );
};

export default ProductView;
