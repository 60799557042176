import React, { useEffect, useRef, useState } from "react";
import ProductView from "./ProductView";
import {
  posAction,
  posSelector,
} from "../../../Redux/Reducers/PosReducers/PosReducers";
import { useDispatch, useSelector } from "react-redux";
import { getProductData } from "../../../Redux/Actions/ProductAction/ProductAction";
import {
  getProduct,
  productSelector,
  updateStockWithProductCart,
} from "../../../Redux/Reducers/ProductReducers/ProductReducers";
import { getSuggestionProductNameForPOS } from "../../../Redux/Actions";
import { isEmpty } from "../../../Utils";
import { useDebounce } from "../../../hooks/useDebounce";
import { settingSelector } from "../../../Redux/Reducers/Slices";

const ProductContainer = ({ searchValue, setSearchValue }) => {
  const [productValueObj, setProductValueObj] = useState({
    page: 1,
    limit: 12,
    isLoading: true,
  });
  const [change, setChange] = useState(false);
  const dispatch = useDispatch();
  const [filterDropDownValue, setFilterDropdownValue] = useState("All");
  const { productData } = useSelector(productSelector);
  const { systemSettingDetails } = useSelector(settingSelector);
  const { currentPage, total, limit, suggestionList } =
    useSelector(posSelector);
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const listRef = useRef(null);
  const { mixMatch } = useSelector((state) => state?.mixMatch);
  const { productToCart } = useSelector(posSelector);

  useEffect(() => {
    if (change) {
      dispatch(posAction?.mixMatchDiscount({ mixMatch, productToCart }));
      dispatch(posAction?.bulkItemDiscount({ mixMatch, productToCart }));
      setChange(false);
    }
  }, [change]);

  const handleGetProduct = async (searchValue, page, limit, filterPayload) => {
    let productValue = { ...productValueObj };
    productValue = {
      ...productValueObj,
      page: page,
      limit: limit,
      isLoading: true,
    };
    const payload = {
      ...(!isEmpty(filterPayload) && filterPayload),
      searchedKeyWord: searchValue,
    };
    const response = await dispatch(
      getProductData(payload, page, limit, "pos")
    );
    productValue = {
      ...productValue,
      isLoading: false,
    };
    if (response?.status === 200) {
      dispatch(getProduct(response?.data));
    }
    setProductValueObj(productValue);
  };

  useEffect(() => {
    handleGetProduct("", productValueObj?.page, productValueObj?.limit, {});
  }, []);

  const createPayload = (e) => {
    let payload;
    if (e !== "All") {
      payload = {
        departmentType:
          e === "Grocery"
            ? "1"
            : e === "Vegetables"
              ? "3"
              : e === "Other"
                ? "2"
                : e === "Fruits"
                  ? "4"
                  : "",
      };
    } else {
      payload = {};
    }
    return payload;
  };

  const handleProductClick = (productObj) => {
    if (
      parseInt(productObj?.productType) === 1 ||
      parseInt(productObj?.productType) === 0
    ) {
      dispatch(posAction?.isPosProductModal(true));
      dispatch(posAction?.posUnpackedProduct(productObj));
      const data = [];
      for (let i = 0; i < productObj?.VegAndFruitsPackages?.length; i++) {
        const payload = {
          productName: productObj?.VegAndFruitsPackages[i]?.packetName,
          tax: productObj?.VegAndFruitsPackages[i]?.tax,
          remainingQuantity:
            productObj?.VegAndFruitsPackages[i]?.newStocks?.[0]
              ?.remainingQuantity,
          price: productObj?.VegAndFruitsPackages[i]?.newStocks?.[0]?.price,
          newStocks: productObj?.VegAndFruitsPackages[i]?.newStocks,
          unit: {
            baseUnit: productObj?.VegAndFruitsPackages[i]?.productUnit,
            operatorValue: 1,
          },
        };
        data.push({ ...productObj, ...payload });
      }
      dispatch(posAction?.posPackedProduct(data));
    } else {
      dispatch(
        posAction.addProductToPOSCart({
          ...productObj,
        })
      );
      dispatch(updateStockWithProductCart({ ...productObj }));
    }
    setChange(true);
  };

  const handlePageChange = (page, pageSize) => {
    setProductValueObj({
      ...productValueObj,
      page: page,
      limit: pageSize,
      isLoading: true,
    });
    const payload = createPayload(filterDropDownValue);
    handleGetProduct(searchValue || "", page, pageSize, payload);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value?.trim());
    if (isEmpty(value)) {
      setSuggestionListLoading(false);
      setShowSuggestionList(false);
      dispatch(posAction.suggestionList([]));
      const payload = createPayload(filterDropDownValue);
      setProductValueObj({
        ...productValueObj,
        isLoading: true,
      });
      handleGetProduct("", currentPage, limit, payload);
    } else {
      setShowSuggestionList(true);
      setSuggestionListLoading(true);
    }
  };

  const handleSelectChange = (e) => {
    setFilterDropdownValue(e);
    const payload = createPayload(e);
    handleGetProduct(searchValue || "", 1, 10, payload);
  };

  const handleSearchData = (value) => {
    const payload = createPayload(filterDropDownValue);
    !isEmpty(value) &&
      handleGetProduct(value, 1, productValueObj?.limit, payload);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setProductValueObj({
        ...productValueObj,
        isLoading: true,
      });
      handleSearchData();
    }
  };

  const handleFocusSearchInput = () => {
    suggestionList.length > 0 && setShowSuggestionList(true);
  };

  const getProductSuggestions = async () => {
    await dispatch(getSuggestionProductNameForPOS(searchValue));
    setSuggestionListLoading(false);
  };

  useDebounce(searchValue, getProductSuggestions);
  return (
    <ProductView
      {...{
        currentPage,
        total,
        limit,
        productValueObj,
        productData,
        handleProductClick,
        handlePageChange,
        handleSearchChange,
        handleKeyDown,
        searchValue,
        filterDropDownValue,
        systemSettingDetails,
        handleSelectChange,
        showSuggestionList,
        setShowSuggestionList,
        suggestionListLoading,
        handleFocusSearchInput,
        getSearchedProduct: handleSearchData,
        suggestionList,
        listRef,
      }}
    />
  );
};

export default ProductContainer;
