const department = [
  {
    departmentCode: "D-001",
    departmentName: "Products",
    departmentKey: "products",
  },
  {
    departmentCode: "D-002",
    departmentName: "Purchase",
    departmentKey: "purchase",
  },
  {
    departmentCode: "D-003",
    departmentName: "New Stock",
    departmentKey: "new-stock",
  },
  {
    departmentCode: "D-004",
    departmentName: "Point of Sales (POS)",
    departmentKey: "point-of-sale",
  },
  {
    departmentCode: "D-005",
    departmentName: "Vegetable and Fruits",
    departmentKey: "veg-fruit",
  },
  {
    departmentCode: "D-006",
    departmentName: "Sales",
    departmentKey: "sales",
  },
  {
    departmentCode: "D-007",
    departmentName: "Sales-return",
    departmentKey: "sales-return",
  },
  {
    departmentCode: "D-008",
    departmentName: "Purchase-return",
    departmentKey: "purchase-return",
  },
  {
    departmentCode: "D-009",
    departmentName: "Performa Invoice",
    departmentKey: "quotation", // department key is not change for perform invoice
  },
  {
    departmentCode: "D-010",
    departmentName: "Discount",
    departmentKey: "discount",
  },
  {
    departmentCode: "D-011",
    departmentName: "Customer",
    departmentKey: "customer",
  },
  {
    departmentCode: "D-012",
    departmentName: "User",
    departmentKey: "user",
  },
  {
    departmentCode: "D-013",
    departmentName: "Supplier",
    departmentKey: "supplier",
  },
  {
    departmentCode: "D-014",
    departmentName: "System-setting",
    departmentKey: "system-setting",
  },
  {
    departmentCode: "D-015",
    departmentName: "Group-permission",
    departmentKey: "group-permission",
  },
  {
    departmentCode: "D-016",
    departmentName: "Brand",
    departmentKey: "brand",
  },
  {
    departmentCode: "D-017",
    departmentName: "Category",
    departmentKey: "category",
  },
  {
    departmentCode: "D-018",
    departmentName: "Currency",
    departmentKey: "currency",
  },
  {
    departmentCode: "D-019",
    departmentName: "Unit",
    departmentKey: "unit",
  },
  {
    departmentCode: "D-020",
    departmentName: "Report",
    departmentKey: "report",
  },
  {
    departmentCode: "D-021",
    departmentName: "Department",
    departmentKey: "department",
  },
  {
    departmentCode: "D-022",
    departmentName: "Expenses",
    departmentKey: "expenses",
  },
];

export default department;
