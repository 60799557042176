import { jwtDecode } from "jwt-decode";
import { AUTH_TOKEN } from "../Constant/primitive";
import { LOG_IN } from "../Constant/routeConstant";
import isEmpty from "./isEmpty/isEmpty";
import { profileAction } from "../Redux/Reducers/ProfileReducer/ProfileReducer";

const handleLogout = () => {
  sessionStorage.clear();
  sessionStorage.clear();
  window.location.href = LOG_IN;
};

const isTokenValid = () => (dispatch) => {
  let TOKEN = JSON.parse(sessionStorage.getItem(AUTH_TOKEN));
  if (isEmpty(TOKEN)) {
    TOKEN = JSON.parse(sessionStorage.getItem(AUTH_TOKEN));
  }
  if (!isEmpty(TOKEN)) {
    const decoded = jwtDecode(TOKEN);
    dispatch(profileAction?.jwtInfo(decoded));
    sessionStorage.setItem("role", decoded?.role);
    if (!decoded || Date.now() / 1000 > decoded?.exp) {
      handleLogout();
      return false;
    }
  }
  return TOKEN;
};

export default isTokenValid;
