import React from "react";
import { PRODUCTS_FOR_PURCHASE_CART } from "../../../Constant/TableConst";
import "../NewPurchase/NewPurchase.scss";
import { Col, Row } from "antd";
import {
  ButtonComponent,
  FormFieldsComponent,
  ImageComponent,
  LottieImage,
  ModalComponent,
  TableContainer,
} from "../../../CommonComponent";
import { loader, searchIcon } from "../../../assest";
import { DuePaymentModel, PurchasePaymentModelView } from "../../../Component";
import { isEmpty } from "../../../Utils";
import SuggestionListContainer from "../../../CommonComponent/SuggestionList/SuggestionListContainer";

const NewPurchaseView = (props) => {
  const {
    id,
    productsTaxTotal,
    subTotal,
    discountTotal,
    error,
    isSearchLoading,
    isDueModelOpen,
    isPurchaseLoading,
    isModelOpen,
    formFields,
    supplierList,
    supplierDetails,
    searchValue,
    newPurchaseValue,
    editPurchaseHistory,
    systemSettingDetails,
    purchaseProductCartData,
    grandTotal,
    isBtnDisable,
    isModelBtnDisabled,
    totalError,
    setTotalError,
    handleChange,
    handleSelectChange,
    handleSearchChange,
    handleProductQuantityChange,
    handleProductQuantitySelectChange,
    handleKeyDown,
    handleAddItem,
    handleRemoveItem,
    handleDeleteItem,
    // handleClickSave,
    handleCloseModel,
    handleOpenDueModel,
    handleCloseDueModel,
    handlePayment,
    handleSelect,
    handleProductCode,
    handleUpdatePurchase,
    showSuggestionList,
    setShowSuggestionList,
    suggestionListLoading,
    handleFocusSearchInput,
    getSearchedProduct,
    suggestionList,
    listRef,
  } = props;
  return (
    <div className="new-purchase-main">
      {id && isEmpty(editPurchaseHistory) ? (
        <React.Fragment>
          <LottieImage
            lottieImage={loader}
            lottieText={""}
            divClassName={"loader-animation-main"}
            imageClassName={"hold-product-loader"}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="purchase-new-wrap">
            <Row gutter={[20, 0]} className="new-purchase-input-main">
              {Object.keys(formFields)?.map((field) => {
                const {
                  label,
                  name,
                  placeholder,
                  type,
                  disabledDate,
                  validation,
                  format,
                  showSearch,
                } = formFields[field];
                const options =
                  name === "supplierName"
                    ? supplierList
                    : formFields[field]?.options;
                return (
                  <Col
                    key={name}
                    span={24}
                    xxl={8}
                    xl={8}
                    lg={8}
                    md={8}
                    sm={12}
                    xs={24}
                  >
                    <FormFieldsComponent
                      {...{
                        label,
                        name,
                        type,
                        placeholder,
                        options,
                        showSearch,
                        ...(disabledDate && { disabledDate }),
                        ...(format && { format }),
                        ...(validation?.maxLength && {
                          maxLength: validation?.maxLength,
                        }),
                        disabled:
                          !isEmpty(editPurchaseHistory) && type === "datepicker"
                            ? true
                            : false,
                        value: newPurchaseValue[name],
                        handleChange,
                        handleKeyDown: () => {},
                        handleBlur: () => {},
                        handleSelectChange,
                      }}
                    />
                  </Col>
                );
              })}
              <Col span={24} xxl={8} xl={8} lg={8} md={12} sm={12} xs={24}>
                {supplierDetails?.PurchaseReturnCredits?.length > 0 &&
                  supplierDetails?.PurchaseReturnCredits?.[0]
                    ?.remaningCreaditAmount > 0 && (
                    <FormFieldsComponent
                      {...{
                        type: "text",
                        disabled: true,
                        value:
                          supplierDetails?.PurchaseReturnCredits?.[0]
                            ?.remaningCreaditAmount,
                        label: "Credit Amount",
                      }}
                    />
                  )}
              </Col>
            </Row>
            <div className="purchase-search-product" ref={listRef}>
              <FormFieldsComponent
                {...{
                  type: "text",
                  placeholder:
                    "Search by Product Code / Product Number / Product Name / Barcode",
                  name: "Product",
                  label: "Choose Product",
                  value: searchValue,
                  prefix: (
                    <ImageComponent
                      imageSrc={searchIcon}
                      imageAlt={"search-icon"}
                      imageClassName={"search-icon"}
                    />
                  ),
                  handleChange: handleSearchChange,
                  handleKeyDown,
                  handleBlur: () => {},
                  handleOnFocus: handleFocusSearchInput,
                }}
              />
              <SuggestionListContainer
                {...{
                  listRef,
                  showSuggestionList,
                  suggestionList,
                  suggestionListLoading,
                  setShowSuggestionList,
                  getSearchedProduct,
                }}
              />
            </div>
            <div className="purchase-table-main">
              <TableContainer
                {...{
                  tableTitle: "Selected Products For Purchase",
                  column: PRODUCTS_FOR_PURCHASE_CART(
                    systemSettingDetails,
                    handleAddItem,
                    handleRemoveItem,
                    handleProductQuantityChange,
                    handleProductQuantitySelectChange,
                    handleDeleteItem,
                    handleProductCode
                  ),
                  dataSource: [...purchaseProductCartData, { subtotal: 0.0 }],
                  isSuggestionListVisible: true,
                  showSuggestionList,
                  setShowSuggestionList,
                  suggestionListLoading,
                  handleFocusSearchInput,
                  getSearchedProduct,
                  suggestionList,
                  listRef,
                  loading: isSearchLoading,
                }}
                classNames={"purchase-table"}
              />
            </div>
            <Row gutter={[30, 0]} className="purchase-order-table-main">
              <Col
                span={24}
                xxl={6}
                xl={8}
                lg={8}
                md={16}
                sm={24}
                className="purchase-bill-wrap"
              >
                <div className="purchase-bill-main">
                  <div className="grand-total-main">
                    <h1 className="order-tax-title">SUB TOTAL :</h1>
                    <p className="order-tax-title">
                      {systemSettingDetails?.currency}
                      {subTotal}
                    </p>
                  </div>
                  <div className="grand-total-main">
                    <h1 className="order-tax-title">TAX :</h1>
                    <p className="order-tax-title">
                      {systemSettingDetails?.currency}
                      {productsTaxTotal}
                    </p>
                  </div>
                  <div className="grand-total-main">
                    <h1 className="order-tax-title">DISCOUNT :</h1>
                    <p className="order-tax-title">
                      {systemSettingDetails?.currency}
                      {discountTotal}
                    </p>
                  </div>
                  <div className="grand-total-main">
                    <h1 className="order-tax-title">CREDIT AMOUNT :</h1>
                    <p className="order-tax-title">
                      {systemSettingDetails?.currency}
                      {supplierDetails?.PurchaseReturnCredits?.length > 0
                        ? parseFloat(
                            supplierDetails?.PurchaseReturnCredits?.[0]
                              ?.remaningCreaditAmount
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                  <div className="grand-total-main">
                    <h1 className="order-tax-title">GRAND TOTAL :</h1>
                    <p className="order-tax-title">
                      {systemSettingDetails?.currency}
                      {grandTotal > 0 ? grandTotal : "0.00"}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <FormFieldsComponent
          type={"textarea"}
          placeholder={"Add a note"}
          name={"notes"}
          label={"Notes"}
          rows={5}
          TextareaClassNames={"purchase-notes-textarea"}
        /> */}
          </div>
          <div className="btn-main btn-fixed">
            {id && (
              <ButtonComponent
                btnName={id ? "Update" : "Advance"}
                btnClass={"save-btn"}
                btnDisabled={isBtnDisable()}
                handleClick={handleUpdatePurchase}
                isStatus={id && isPurchaseLoading}
              />
            )}
            {!id && (
              <ButtonComponent
                btnName={"Save"}
                btnClass={"save-payment-btn"}
                btnDisabled={isBtnDisable()}
                handleClick={() => handlePayment("partially")}
                isStatus={isPurchaseLoading}
              />
            )}
          </div>
          {isModelOpen && (
            <ModalComponent
              modalOpen={isModelOpen}
              modalTitle={"Purchase"}
              handleModalCancel={handleCloseModel}
              modalClass={"purchase-Payment-modal"}
              modalWidth={870}
            >
              <PurchasePaymentModelView
                {...{
                  status: "complete",
                  isPurchaseLoading,
                  newPurchaseValue,
                  supplierList,
                  error,
                  totalError,
                  setTotalError,
                  purchaseProductCartData,
                  systemSettingDetails,
                  handleSelectChange,
                  handleSelect,
                  handleCloseModel,
                  handlePayment,
                  isModelBtnDisabled,
                  handleChange,
                  handleOpenDueModel,
                  isBtnDisable,
                  isCreditAmountDisabled:
                    supplierDetails?.PurchaseReturnCredits?.length > 0
                      ? supplierDetails?.PurchaseReturnCredits?.[0]
                          ?.remaningCreaditAmount > 0
                        ? false
                        : true
                      : true,
                }}
              />
            </ModalComponent>
          )}
          {isDueModelOpen && (
            <ModalComponent
              modalOpen={isDueModelOpen}
              modalTitle={"Purchase"}
              handleModalCancel={handleCloseDueModel}
              modalClass={"purchase-save-modal"}
              modalWidth={650}
            >
              <DuePaymentModel
                {...{
                  isLoading: isPurchaseLoading,
                  status: "partially",
                  handleChange,
                  handleSelect,
                  handleCloseDueModel,
                  handlePayment,
                  handleSelectChange,
                  isPurchaseCreate: true,
                  newPurchaseValue,
                  error,
                  totalError,
                }}
              />
            </ModalComponent>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default NewPurchaseView;
