import React from "react";
import ImageComponent from "../Image/ImageComponent";
import { arrowLeft, arrowRight } from "../../assest";

const QuantityComponent = ({
  render,
  quantity,
  imgClass,
  mainDiv,
  handleAddItem,
  handleRemoveItem,
}) => {
  return (
    <div className={mainDiv}>
      <ImageComponent
        imageSrc={arrowLeft}
        imageAlt={"company-logo"}
        imageClassName={imgClass}
        handleClick={() => handleRemoveItem(render)}
      />
      <span>{quantity}</span>
      <ImageComponent
        imageSrc={arrowRight}
        imageAlt={"company-logo"}
        imageClassName={imgClass}
        handleClick={() => handleAddItem(render)}
      />
    </div>
  );
};

export default QuantityComponent;
