import React from "react";
import {
  FormFieldsComponent,
  ImageComponent,
  LottieImage,
  TableContainer,
} from "../../../CommonComponent";
import "../ProductPOSList/productPOSList.scss";
import { isEmpty } from "../../../Utils";
import { noDataFound, searchIcon } from "../../../assest";
import { POS_PRODUCT_LIST_COLUMN } from "../../../Constant/TableConst";

const ProductPOSListView = ({
  productToCart,
  handleRemoveItem,
  handleAddItem,
  handleDeleteItem,
  searchValue,
  handleSearchChange,
  handleKeyDown,
  handleChange,
  systemSettingDetails,
  handleBlur,
  getMixMatchDetails,
}) => {
  return (
    <div className="pos-list-table-main">
      <FormFieldsComponent
        name={"Product"}
        type={"text"}
        placeholder={"Search product by code"}
        inputClass={"choose-product-search"}
        inputMain={"input-main"}
        prefix={
          <ImageComponent
            imageSrc={searchIcon}
            imageAlt={"search-icon"}
            imageClassName={"search-icon"}
          />
        }
        value={searchValue}
        handleChange={handleSearchChange}
        handleKeyDown={handleKeyDown}
        handleBlur={() => {}}
      />
      <div className="pos-list-main">
        {isEmpty(productToCart) ? (
          <div className="no-data-main">
            <LottieImage
              lottieImage={noDataFound}
              lottieText={"No Products In Cart"}
              divClassName={"page-not-found-main"}
              textClassName={"not-found-text"}
              imageClassName={"page-not-found-icon"}
            />
          </div>
        ) : (
          <TableContainer
            {...{
              column: POS_PRODUCT_LIST_COLUMN(
                handleRemoveItem,
                handleAddItem,
                handleDeleteItem,
                handleChange,
                systemSettingDetails,
                handleBlur,
                getMixMatchDetails
              ),
              dataSource: productToCart,
              // setShowSuggestionList: () => {},
            }}
            classNames={"Product-added-table-main"}
          />
        )}
      </div>
    </div>
  );
};

export default ProductPOSListView;
