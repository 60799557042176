const permission = [
  {
    permissionCode: "P-001",
    permissionName: "View",
    permissionKey: "view",
  },
  {
    permissionCode: "P-002",
    permissionName: "Delete",
    permissionKey: "delete",
  },
  {
    permissionCode: "P-003",
    permissionName: "Edit",
    permissionKey: "edit",
  },
  {
    permissionCode: "P-004",
    permissionName: "Create",
    permissionKey: "create",
  },
  {
    permissionCode: "P-005",
    permissionName: "ALL",
    permissionKey: "all",
  },
  {
    permissionCode: "P-006",
    permissionName: "Purchase Report View",
    permissionKey: "purchase-view",
  },
  {
    permissionCode: "P-007",
    permissionName: "Purchase Payment Report View",
    permissionKey: "purchase-payment-view",
  },
  {
    permissionCode: "P-008",
    permissionName: "Purchase Return Report View",
    permissionKey: "purchase-return-view",
  },
  {
    permissionCode: "P-009",
    permissionName: "Sale Report View",
    permissionKey: "sale-view",
  },
  {
    permissionCode: "P-010",
    permissionName: "Sale Payment Report View",
    permissionKey: "sale-payment-view",
  },
  {
    permissionCode: "P-011",
    permissionName: "Sale Return Report View",
    permissionKey: "sale-return-view",
  },
  {
    permissionCode: "P-012",
    permissionName: "Top Least Selling View",
    permissionKey: "top-least-selling-view",
  },
  {
    permissionCode: "P-013",
    permissionName: "Product Report View",
    permissionKey: "product-report-view",
  },
  {
    permissionCode: "P-014",
    permissionName: "Stock Report View",
    permissionKey: "stock-report-view",
  },
  {
    permissionCode: "P-015",
    permissionName: "Customer Report View",
    permissionKey: "customer-report-view",
  },
  {
    permissionCode: "P-016",
    permissionName: "Supplier Report View",
    permissionKey: "supplier-report-view",
  },
  {
    permissionCode: "P-017",
    permissionName: "User Report View",
    permissionKey: "user-report-view",
  },
  {
    permissionCode: "P-018",
    permissionName: "Z Report View",
    permissionKey: "z-report-view",
  },
  {
    permissionCode: "P-019",
    permissionName: "Stock Evaluation Report View",
    permissionKey: "stock-evaluation-report-view",
  },
  {
    permissionCode: "P-020",
    permissionName: "Expenses Report View",
    permissionKey: "expense-report-view",
  },
];

export default permission;
