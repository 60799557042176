import { Select } from "antd";
import React from "react";
import { isEmpty } from "../../Utils";
const { Option } = Select;

const CustomerSearchView = ({
  inputMain,
  error,
  placeholder,
  handleGetCustomerData,
  options,
  SelectClassNames,
  // handleChange,
  value,
  handleSelectChange,
}) => {
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().startsWith(input.toLowerCase());
  return (
    <div className={`${inputMain} input-area-main`}>
      <Select
        placeholder={placeholder}
        defaultOpen={false}
        allowClear={false}
        showSearch
        onSearch={handleGetCustomerData}
        onChange={(e) => handleSelectChange(e, "customer", "customer")}
        options={options}
        filterOption={filterOption}
        className={`dropdown_input ${SelectClassNames}`}
        value={value}
      >
        {options &&
          options?.map((obj) => (
            <Option value={obj?.value} key={obj?.label}>
              {obj?.label}
            </Option>
          ))}
      </Select>
      {!isEmpty(error) && <p className="error-msg">{error}</p>}
    </div>
  );
};

export default CustomerSearchView;
