import React, { useEffect, useState } from "react";
import PaymentModalView from "./PaymentModalView";
import { useDispatch, useSelector } from "react-redux";
import {
  getPosSetting,
  getProductData,
  getReferenceId,
  getSystemSetting,
} from "../../../Redux/Actions";
import {
  getProduct,
  peopleSelector,
  posAction,
  posSelector,
  settingSelector,
} from "../../../Redux/Reducers/Slices";
import { useQuery } from "@tanstack/react-query";
import { STALE_TIME } from "../../../Constant/primitive";
import {
  saleAction,
  saleSelector,
} from "../../../Redux/Reducers/SaleReducer/SaleReducer";
import {
  getPosTotalWholeSalePrice,
  getTotalTaxValue,
} from "../../../Utils/PriceCalculation/PosPriceCalculation";

const PaymentModalContainer = ({
  handleCloseModal,
  handlePayment,
  paymentModal,
  handlePrint,
  componentRef,
  isOpenPaymentMethod,
  paymentSuccessDetails,
  setPaymentModal,
  setRedeem,
  setPound,
}) => {
  const dispatch = useDispatch();
  const {
    ReferenceNumber,
    grandTotal,
    customerId,
    paymentMode,
    productToCart,
    paymentCashSubTotal,
    productsTaxTotal,
    paymentBankSubTotal,
    bankDetailsInfo,
    discountTotal,
    mixMatchDiscountTotal,
  } = useSelector(posSelector);
  const { customerData } = useSelector(peopleSelector);
  const { subTotal } = useSelector(saleSelector);

  const { PosCustomerName, addProductToPOSCart, paymentMethod } = posAction;
  const { posReceiptSetting, systemSettingDetails } =
    useSelector(settingSelector);
  const [isStatus, setIsStatus] = useState(false);
  const handleNextOrder = async () => {
    setIsStatus(true);

    await dispatch(getReferenceId());
    const payload = {
      searchedKeyWord: "",
    };
    const response = await dispatch(getProductData(payload, 1, 12, "pos"));
    if (response?.status === 200) {
      dispatch(getProduct(response?.data));
    }
    dispatch(paymentMethod("cash"));
    dispatch(PosCustomerName("WIC"));
    dispatch(addProductToPOSCart([]));
    setIsStatus(false);
    setRedeem(false);
    setPound(0);
    handleCloseModal();
  };

  const handleGetPosSetting = async () => {
    const response = await dispatch(getPosSetting());
    return response;
  };

  const { isLoading: isCategoryLoading } = useQuery({
    queryKey: ["posSetting"],
    queryFn: () => handleGetPosSetting(),
    staleTime: STALE_TIME,
  });

  const handleGetSettingData = async () => {
    await dispatch(getSystemSetting());
  };

  const { isLoading: isSystemSettingLoading } = useQuery({
    queryKey: ["systemSetting"],
    queryFn: () => handleGetSettingData(),
    staleTime: STALE_TIME,
  });
  useEffect(() => {
    let timer;
    if (paymentModal?.isOpen) {
      timer = setTimeout(async () => {
        await handleNextOrder();
        setPaymentModal({ ...paymentModal, isOpen: false });
      }, 5000); // 30 seconds
    }
    return () => clearTimeout(timer);
  }, [paymentModal?.isOpen]);

  useEffect(() => {
    console.log(productToCart, paymentSuccessDetails);
    const subTotalPrice = getPosTotalWholeSalePrice(productToCart);
    const subTotalTax = getTotalTaxValue(
      productToCart,
      paymentSuccessDetails?.transactionType === 0 ? "price" : "wholeSalePrice"
    );
    console.log(subTotalPrice, subTotalTax);
    dispatch(posAction.taxTotal(subTotalTax));
    dispatch(saleAction.subTotal(subTotalPrice));
  }, [productToCart]);

  console.log(paymentCashSubTotal, paymentBankSubTotal, subTotal);

  return (
    <PaymentModalView
      {...{
        subTotal,
        paymentModal,
        bankDetailsInfo,
        handlePayment,
        handleNextOrder,
        isStatus,
        handlePrint,
        ReferenceNumber,
        grandTotal,
        discountTotal,
        mixMatchDiscountTotal,
        customerId,
        customerData,
        paymentMode,
        productToCart,
        componentRef,
        isOpenPaymentMethod,
        paymentSuccessDetails,
        paymentCashSubTotal,
        paymentBankSubTotal,
        productsTaxTotal,
        isCategoryLoading,
        isSystemSettingLoading,
        posReceiptSetting,
        systemSettingDetails,
      }}
    />
  );
};

export default PaymentModalContainer;
