/* eslint-disable no-useless-escape */
export const AUTH_TOKEN = "authToken";

export const EURO_SYMBOL = "€";
export const STALE_TIME = 15000;

export const REQUIRED = "Required";
export const INVALID_EMAIL = "Invalid email";
export const EMAIL_REQUIRED = "Email is required";
export const ROLE_REQUIRED = "Role is required";
export const ROLE_DESCRIPTION_REQUIRED = "Role Description is required";
export const PASSWORD_REQUIRED = "Password is required";
export const VALID_PASSWORD =
  "Password required 1 uppercase, lowercase, number, and special character.";
export const MIN_PASSWORD_LENGTH =
  "Password is too short - should be 8 chars minimum.";
export const NOT_FOUND_BARCODE =
  "Stock not found";
export const TEXT_COPY_SUCCESS = "copied to clipboard!";
export const TEXT_COPY_FAILED =
  "Failed to copy text to clipboard. Please try again.";
export const RETAIL_PRICE_MESSAGE =
  "WholeSale product percentage can not be greater then  Retail product percentage";

export const MIN_BARCODE_ID_LENGTH = "BarcodeId must be 13 digit";
export const PRODUCT_NAME = "Product Name Required";
export const WAREHOUSE = "Warehouse Required";
export const BARCODE_ID = "BarcodeID is required ";
export const DEPARTMENT_REQUIRED = "Department is required"; 
export const SUPPLIER_NAME = "Supplier Name is required";
export const PRODUCT_NUMBER = "Product Number is required";
export const CATEGORY = "Category is required";
export const PRODUCT_BRAND = "Brand is required";
export const PRODUCT_RETAIL_PRICE = "Retail Price is required";
export const PRODUCT_WHOLESALE_PRICE = "Wholesale Price is required";
export const PRODUCT_WEBSITE_PRICE = "Website Price is required";
export const PRODUCT_COST = "Purchase Price is required";
export const PRODUCT_UNIT = "Product Unit is required";
export const PRODUCT_QUANTITY = "Product Quantity is required";
export const PRODUCT_QUANTITY_RAGAX = "Please enter a valid product quantity";
export const STOCK_ALERT = "Stock is required ";
export const EXPIRY_DATE = "Expiry Date is required";
export const COUNTRY = "Country is required";

export const STOCK_ALERT_MAX = "StockAlert can't add more than productQuantity";
export const QUANTITY_MAX = "Product quantity can't add more than productQuantity";
export const NEGATIVE_QUANTITY_MAX = "Quantity can not be remove more then remaningQty"

export const IMAGE_REQUIRED = "Image is required";
export const CURRENT_STOCK = "Current Stock is required";
export const PRODUCT_NOTES = "Please Enter Notes";
export const PRODUCT_TAX = "Tax is required";
export const CUSTOMER_REQUIRED = "Customer is Required";
export const PRODUCT_PRICE_ERROR_MESSAGE = "Product price must be a number";
export const PRODUCT_ID_ERROR_MESSAGE = "Product price must be a number";
export const MAX_50_CHAR = "Maximum 50 characters";
export const LAST_DATE_STOCK = "Please  select date";
export const EXPIRY_DATE_STOCK = "Please select expiry date";
export const IMAGE_SIZE = "File size must be less than 500Kb.";
export const CURRENT_STOCK_REQUIRED = "Current stock is required";
export const STOCK_ALERT_REQUIRED = "Stock alert is required";
export const SOLD_QUANTITY_REQUIRED = "Sold quantity is required";
export const LAST_ADD_STOCK = "Last stock date is required";
export const ONLY_NUMBER_REQUIRED = "Please enter only numbers.";

export const SUCCESS_STATUS = [200, 201];
