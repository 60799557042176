import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewPurchaseView from "./NewPurchaseView";
import {
  createPurchase,
  getProductData,
  getPurchaseHistoryById,
  getSupplier,
  updatePurchase,
  getSuggestionProductNameForPurchase,
} from "../../../Redux/Actions";
import {
  NEW_PURCHASE_FORM_SCHEMA,
  newPurchaseInitialValues,
} from "../../../FormSchema/PurchaseSchema";
import {
  peopleSelector,
  posAction,
  posSelector,
  settingSelector,
} from "../../../Redux/Reducers/Slices";
import {
  convertDateIntoYYYYMMDD,
  getPurchaseDiscountTotal,
  getPurchaseGrandTotal,
  getPurchaseSubTotal,
  getPurchaseTotalTaxValue,
  isEmpty,
} from "../../../Utils";
import {
  purchaseAction,
  purchaseSelector,
} from "../../../Redux/Reducers/PurchaseReducer/PurchaseReducer";
import {
  DOT_WITH_NUMBER,
  NUMBER_REGEX,
  NUMBER_WITH_DOTE_REGEX,
} from "../../../Constant/regexConstant";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useDebounce } from "../../../hooks/useDebounce";
import { OpenNotificationComponent } from "../../../CommonComponent";
// import { getSuggestionProductNameForPurchase } from "../../../Redux/Actions/PurchaseAction/PurchaseAction";
// import { useDebounce } from "../../../hooks/useDebounce";

const NewPurchaseContainer = () => {
  const [newPurchaseValue, setNewPurchaseValue] = useState(
    newPurchaseInitialValues
  );
  const [searchValue, setSearchValue] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isDueModelOpen, setIsDueModelOpen] = useState(false);
  const [showSuggestionList, setShowSuggestionList] = useState(false);
  const [suggestionListLoading, setSuggestionListLoading] = useState(false);
  const listRef = useRef(null);
  const [error, setError] = useState();
  const [totalError, setTotalError] = useState("");

  const dispatch = useDispatch();
  const { supplierData } = useSelector(peopleSelector);
  const {
    purchaseProductCartData,
    editPurchaseHistory,
    subTotal,
    discountTotal,
    suggestionList,
  } = useSelector(purchaseSelector);
  const { systemSettingDetails } = useSelector(settingSelector);
  const { grandTotal, productsTaxTotal } = useSelector(posSelector);
  const {
    addProductToPurchaseCart,
    removeProductToPurchaseCart,
    deleteProductToPurchaseCart,
  } = purchaseAction;
  const { taxTotal } = posAction;
  const { id } = useParams();

  const formFields = NEW_PURCHASE_FORM_SCHEMA;
  useEffect(() => {
    const handleFetchData = async () => {
      await dispatch(getSupplier());
    };
    handleFetchData();
    return () => {
      dispatch(purchaseAction.editPurchaseHistory({}));
    };
  }, []);

  useEffect(() => {
    dispatch(purchaseAction.editPurchaseHistory({}));
    dispatch(purchaseAction.purchaseProductCartData([]));
    setNewPurchaseValue(newPurchaseInitialValues);
  }, [id]);

  useEffect(() => {
    if (id) {
      const handleGetRecordById = async () => {
        await dispatch(getPurchaseHistoryById(id));
      };
      handleGetRecordById();
    }
  }, [id]);

  const supplierDetails = useMemo(() => {
    return supplierData?.find(
      (ele) => ele?.supplierId === newPurchaseValue?.supplierName
    );
  }, [newPurchaseValue, supplierData]);

  useEffect(() => {
    if (!isEmpty(editPurchaseHistory)) {
      const supplierDetails = supplierData?.find(
        (ele) =>
          ele?.supplierId === editPurchaseHistory?.SupplierModel?.supplierName
      );
      setNewPurchaseValue({
        ...newPurchaseValue,
        purchaseDate: convertDateIntoYYYYMMDD(
          editPurchaseHistory?.purchaseDate
        ),
        supplierName: editPurchaseHistory?.SupplierModel?.supplierId,
        purchaseInvoiceNumber: editPurchaseHistory?.purchaseInvoiceNumber,
        creditAmount:
          supplierDetails?.PurchaseReturnCredits?.length > 0
            ? supplierDetails?.PurchaseReturnCredits?.[0]?.remaningCreaditAmount
            : 0,
      });
    }
  }, [editPurchaseHistory]);

  useEffect(() => {
    const subTotal = getPurchaseSubTotal(purchaseProductCartData);
    dispatch(purchaseAction.subTotal(subTotal));
    const taxTotalValue = getPurchaseTotalTaxValue(
      purchaseProductCartData,
      "purchasePrice"
    );
    dispatch(taxTotal(taxTotalValue));
    const discountPrice = getPurchaseDiscountTotal(purchaseProductCartData);
    dispatch(purchaseAction.discountTotal(discountPrice));
  }, [purchaseProductCartData]);

  const supplierList = useMemo(() => {
    return supplierData?.map((ele) => {
      return {
        label: ele?.supplierName,
        value: ele?.supplierId,
      };
    });
  }, [supplierData]);

  useEffect(() => {
    const grandTotal = getPurchaseGrandTotal(
      subTotal,
      productsTaxTotal,
      discountTotal
    );
    const total =
      grandTotal > 0 && supplierDetails?.PurchaseReturnCredits?.length > 0
        ? grandTotal -
          supplierDetails?.PurchaseReturnCredits?.[0]?.remaningCreaditAmount
        : grandTotal;
    dispatch(posAction.grandTotal(parseFloat(total).toFixed(2)));
  }, [
    supplierDetails?.PurchaseReturnCredits,
    subTotal,
    productsTaxTotal,
    discountTotal,
  ]);

  const isBtnDisable = () => {
    let isDisabled;
    if (
      isEmpty(newPurchaseValue?.purchaseDate) ||
      isEmpty(newPurchaseValue?.supplierName) ||
      isEmpty(newPurchaseValue?.purchaseInvoiceNumber) ||
      purchaseProductCartData?.length <= 0
    ) {
      isDisabled = true;
    } else {
      const validateProduct = (product) => {
        if (
          !product?.qtyPerBag ||
          !product?.bag ||
          !product?.purchasePrice ||
          !product?.taxTotal
        ) {
          return false;
        }

        const qtyPerBag = Number(product.qtyPerBag);
        const bag = Number(product.bag);
        const purchasePrice = Number(product.purchasePrice);

        if (qtyPerBag <= 0 || bag < 0 || purchasePrice <= 0) {
          return false;
        }

        return true; // Product is valid
      };
      const allProductsValid = purchaseProductCartData.every(validateProduct);

      isDisabled = !allProductsValid;
    }
    return isDisabled;
  };

  const isModelBtnDisabled = () => {
    if (
      isEmpty(newPurchaseValue?.paymentMode) ||
      !isEmpty(error?.creditAmount)
    ) {
      return true;
    }
    return false;
  };

  const handleSelect = (e, type, name) => {
    setNewPurchaseValue({
      ...newPurchaseValue,
      [name]: type,
    });
  };

  const handleChange = (e, type, name) => {
    let newPurchaseData = { ...newPurchaseValue };
    if (type === "datepicker") {
      newPurchaseData = {
        ...newPurchaseValue,
        [name]: convertDateIntoYYYYMMDD(e),
      };
    } else {
      if (type === "price") {
        const { value } = e;
        if (name === "creditAmount") {
          if (supplierDetails?.PurchaseReturnCredits?.length > 0) {
            if (
              Number(value) >
              Number(
                supplierDetails?.PurchaseReturnCredits?.[0]
                  ?.remaningCreaditAmount
              )
            ) {
              setError({
                ...error,
                [name]: "Entered amount can't be more than credit amount",
              });
            } else {
              setError({
                ...error,
                [name]: "",
              });
            }
          }
        }
        newPurchaseData = {
          ...newPurchaseValue,
          [name]: value,
        };
      } else {
        let { value } = e.target;
        const { name } = e.target;
        const regex = formFields[name]?.validation?.regex;
        if (regex) {
          value = value?.replace(regex, "");
        }
        newPurchaseData = {
          ...newPurchaseValue,
          [name]: value,
        };
      }
    }
    setNewPurchaseValue(newPurchaseData);
  };

  const handleSelectChange = (e, name) => {
    if (name == "paymentMode" && e != "multi") {
      setNewPurchaseValue({
        ...newPurchaseValue,
        isBank: false,
        isCash: false,
        [name]: e,
      });
      setTotalError("");
    } else {
      setNewPurchaseValue({
        ...newPurchaseValue,
        [name]: e,
      });
    }
  };

  const handleGetProduct = async (searchValue) => {
    setIsSearchLoading(true);
    if (purchaseProductCartData.length > 0) {
      for (let i = 0; i < purchaseProductCartData.length; i++) {
        if (
          purchaseProductCartData[i]?.productCode == searchValue ||
          purchaseProductCartData[i]?.productName == searchValue ||
          purchaseProductCartData[i]?.productNumber == searchValue
        ) {
          dispatch(purchaseAction.suggestionList([]));
          setSearchValue("");
          setIsSearchLoading(false);
          OpenNotificationComponent("Product already exist", "warning");
          return;
        }
      }
    }
    const payload = {
      searchedKeyWord: searchValue,
    };
    const response = await dispatch(getProductData(payload, "", "", "others"));
    if (response.status === 200) {
      if (response?.data?.data?.length > 0) {
        const responseData = response?.data?.data?.[0];
        dispatch(addProductToPurchaseCart(responseData));
      }
    }
    dispatch(purchaseAction.suggestionList([]));
    setSearchValue("");
    setIsSearchLoading(false);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value.trim());
    if (isEmpty(value)) {
      setShowSuggestionList(false);
      dispatch(purchaseAction.suggestionList([]));
    } else {
      setShowSuggestionList(true);
      setSuggestionListLoading(true);
    }
  };

  const handleKeyDown = () => {
    // if (e.key === "Enter") {
    //   !isEmpty(searchValue) && handleGetProduct(searchValue);
    // }
  };

  const handleProductQuantityChange = (e, productObj, type) => {
    let productArr = [...purchaseProductCartData];
    if (type === "price") {
      const { value } = e;
      productArr = productArr?.map((ele) => {
        if (ele?.productId === productObj?.productId) {
          const priceTotal = Number(ele?.bag) * value;
          const priceWithTax =
            Number(priceTotal) * Number(productObj?.taxTotal || 0);
          const finalPrice = priceWithTax / 100 + priceTotal;
          return {
            ...ele,
            purchasePrice: value,
            subtotal: finalPrice || 0,
          };
        } else {
          return ele;
        }
      });
    } else {
      const { name } = e.target;
      let { value } = e.target;
      value = value.replace(NUMBER_WITH_DOTE_REGEX, "");
      productArr = productArr?.map((ele) => {
        if (ele?.productId === productObj?.productId) {
          const purchasePrice = !isEmpty(productObj?.purchasePrice)
            ? Number(productObj?.purchasePrice)
            : 0;
          const priceTotal =
            name === "bag"
              ? Number(value) * purchasePrice
              : purchasePrice * ele?.bag;
          const priceWithTax =
            Number(priceTotal) * Number(productObj?.taxTotal || 0);
          const finalPrice = priceWithTax / 100 + priceTotal;
          const discount =
            name === "PurchaseDiscount"
              ? (finalPrice *
                  Number(value > 100 ? ele?.PurchaseDiscount : value)) /
                100
              : ele?.PurchaseDiscount;
          const total = finalPrice - discount;
          return {
            ...ele,
            [name]:
              name === "PurchaseDiscount"
                ? value > 100
                  ? ele?.PurchaseDiscount
                  : value
                : value,
            quantity:
              name === "qtyPerBag"
                ? ele?.bag * value
                : name === "bag"
                  ? ele?.qtyPerBag * value
                  : ele?.quantity,
            subtotal:
              name === "bag" || name === "PurchaseDiscount"
                ? total
                : ele?.subtotal,
          };
        } else {
          return ele;
        }
      });
    }
    dispatch(addProductToPurchaseCart(productArr));
  };

  const handleProductQuantitySelectChange = (e, productObj) => {
    let productArr = [...purchaseProductCartData];
    const { name } = e.target;
    let { value } = e.target;
    value = value.replace(
      name === "taxTotal" ? !DOT_WITH_NUMBER : NUMBER_REGEX,
      ""
    );
    const taxValue =
      value <= 100
        ? value.includes(".")
          ? value?.length <= 4
            ? value
            : productObj?.taxTotal
          : value
        : productObj?.taxTotal;
    productArr = productArr?.map((ele) => {
      if (ele?.productId === productObj?.productId) {
        const priceTotal = Number(ele?.bag) * Number(ele?.purchasePrice);
        const priceWithTax = Number(priceTotal) * Number(taxValue || 0);
        const finalPrice = priceWithTax / 100 + priceTotal;
        const discount = (finalPrice * Number(ele?.PurchaseDiscount)) / 100;
        const total = finalPrice - discount;
        return {
          ...ele,
          taxTotal: taxValue,
          subtotal: total,
        };
      } else {
        return ele;
      }
    });
    dispatch(addProductToPurchaseCart(productArr));
  };

  const handleAddItem = (productObj) => {
    dispatch(
      addProductToPurchaseCart({
        ...productObj,
        operatorValue: productObj?.unit?.operatorValue,
        unit: productObj?.unit?.shortName,
      })
    );
  };

  const handleRemoveItem = (productObj) => {
    dispatch(
      removeProductToPurchaseCart({
        ...productObj,
        operatorValue: productObj?.unit?.operatorValue,
        unit: productObj?.unit?.shortName,
      })
    );
  };

  const handleDeleteItem = (productObj) => {
    dispatch(deleteProductToPurchaseCart(productObj));
  };

  const handleClickSave = () => {
    setIsModelOpen(true);
    setNewPurchaseValue({
      ...newPurchaseValue,
      amount: `${systemSettingDetails?.currency} ${grandTotal}`,
      creditAmount:
        supplierDetails?.PurchaseReturnCredits?.length > 0
          ? supplierDetails?.PurchaseReturnCredits?.[0]?.remaningCreaditAmount
          : 0,
    });
  };

  const handleOpenDueModel = () => {
    setIsDueModelOpen(true);
    setNewPurchaseValue({
      ...newPurchaseValue,
      creditAmount:
        supplierDetails?.PurchaseReturnCredits?.length > 0
          ? supplierDetails?.PurchaseReturnCredits?.[0]?.remaningCreaditAmount
          : 0,
      paymentMode: "",
      isCash: false,
      isBank: false,
    });
  };

  const handleCloseDueModel = () => {
    setIsDueModelOpen(false);
    setNewPurchaseValue({
      ...newPurchaseValue,
      paymentMode: "",
      advanceAmount: "",
      isCash: false,
      isBank: false,
    });
    setTotalError("");
  };

  const handleCloseModel = () => {
    setIsModelOpen(false);
    setNewPurchaseValue({
      ...newPurchaseValue,
      paymentMode: "",
      advanceAmount: "",
      isCash: false,
      isBank: false,
    });
  };

  const handlePaymentSubmit = async ({ payload, status, purchaseId }) => {
    let response;
    if (purchaseId) {
      response = await dispatch(updatePurchase(payload, purchaseId));
    } else {
      response = await dispatch(createPurchase(status, payload));
    }
    return response;
  };

  const handleSuccessMutation = async (response) => {
    if (response.status === 200) {
      if (!id) {
        setNewPurchaseValue(newPurchaseInitialValues);
        setIsModelOpen(false);
        setIsDueModelOpen(false);
        dispatch(purchaseAction.purchaseProductCartData([]));
        dispatch(posAction.grandTotal(0));
      }
    }
  };

  const { mutate, isPending: isPurchaseLoading } = useMutation({
    mutationFn: handlePaymentSubmit,
    onSuccess: handleSuccessMutation,
  });

  const removeDuplicates = (cartData) => {
    const uniqueCartData = [];
    const seenProductIds = {};

    for (let i = 0; i < cartData.length; i++) {
      const item = cartData[i];
      if (!seenProductIds[item.productId]) {
        uniqueCartData.push(item);
        seenProductIds[item.productId] = true;
      }
    }
    return uniqueCartData;
  };

  const handlePayment = (status) => {
    const bankTransferJson = {
      isBankTransfer:
        newPurchaseValue?.paymentMode === "bankTransfer" ? true : false,
      amount:
        newPurchaseValue?.paymentMode === "bankTransfer" ? grandTotal : 0 || 0,
    };

    const cashTransferJson = {
      isCashTransfer: newPurchaseValue?.paymentMode === "cash" ? true : false,
      amount: newPurchaseValue?.paymentMode === "cash" ? grandTotal : 0 || 0,
    };
    if (+grandTotal >= +newPurchaseValue?.advanceAmount) {
      if (newPurchaseValue?.paymentMode === "multi") {
        bankTransferJson.amount = newPurchaseValue?.bankAmount || 0;
        bankTransferJson.isBankTransfer = newPurchaseValue?.isBank;
        cashTransferJson.amount = newPurchaseValue?.cashAmount || 0;
        cashTransferJson.isCashTransfer = newPurchaseValue?.isCash;
        if (+bankTransferJson?.amount > 0 && +cashTransferJson?.amount > 0) {
          if (
            +bankTransferJson.amount + +cashTransferJson.amount !==
            (+newPurchaseValue?.advanceAmount ||
              +newPurchaseValue?.amount?.replace("€", ""))
          ) {
            setTotalError("Total amount is not matching");
            return;
          }
        } else {
          if (
            bankTransferJson?.isBankTransfer &&
            cashTransferJson?.isCashTransfer
          ) {
            setTotalError("You can't input zero in any field.");
          } else {
            setTotalError(
              "You selected multi but only paying through one mode only."
            );
          }
          return;
        }
      }
      setTotalError("");
      const uniqueProduct = removeDuplicates(purchaseProductCartData);
      const payload = {
        supplierId: newPurchaseValue?.supplierName,
        purchaseDate: newPurchaseValue?.purchaseDate,
        purchaseInvoiceNumber: newPurchaseValue?.purchaseInvoiceNumber,
        // terms: newPurchaseValue?.terms,
        // termNumber: newPurchaseValue?.termNumber,
        products: uniqueProduct?.map((product) => {
          const {
            productId,
            quantity,
            purchasePrice,
            productName,
            subtotal,
            bag,
            qtyPerBag,
            purchaseProductId,
            taxTotal,
            remaningQty,
            PurchaseDiscount,
          } = product;
          const productObj = {
            ...(purchaseProductId && { purchaseProductId }),
            productId,
            productName,
            bag: +bag,
            qtyPerBag,
            quantity,
            purchasePrice: purchasePrice,
            subtotal,
            tax: +taxTotal,
            remaningQty: remaningQty,
            PurchaseDiscount: +PurchaseDiscount,
          };
          return productObj;
        }),
        payment: {
          subTotal: grandTotal,
          taxPercentage: 0,
          taxPrice: 0,
          shippingPrice: 0,
          total: grandTotal,
          discountPercentage: 0,
          discountPrice: discountTotal,
          grandTotal: grandTotal,
          bankName: "",
          ...(!id && { advanceAmount: newPurchaseValue?.advanceAmount }),
          ...(!id && { bankName: "" }),
          ...(!id && { bankTransfer: bankTransferJson }),
          ...(!id && { cashTransfer: cashTransferJson }),
          ...(!id && { cashQuantity: [] }),
          ...(!id && {
            paymentMode:
              newPurchaseValue?.paymentMode === "bankTransfer"
                ? "bank-transfer"
                : newPurchaseValue?.paymentMode,
          }),
          ...(!id && {
            creditAmount: newPurchaseValue?.creditAmount || 0,
          }),
        },
      };
      const data = { payload, status, purchaseId: id };
      mutate(data);
    } else {
      // if (newPurchaseValue?.advanceAmount &&){

      // }
      setTotalError("Advance Amount and grand total are not same");
      return;
    }
  };

  const handleUpdatePurchase = (status) => {
    const bankTransferJson = {
      isBankTransfer:
        newPurchaseValue?.paymentMode === "bankTransfer" ? true : false,
      amount:
        newPurchaseValue?.paymentMode === "bankTransfer" ? grandTotal : 0 || 0,
    };

    const cashTransferJson = {
      isCashTransfer: newPurchaseValue?.paymentMode === "cash" ? true : false,
      amount: newPurchaseValue?.paymentMode === "cash" ? grandTotal : 0 || 0,
    };

    const payload = {
      supplierId: newPurchaseValue?.supplierName,
      purchaseDate: newPurchaseValue?.purchaseDate,
      purchaseInvoiceNumber: newPurchaseValue?.purchaseInvoiceNumber,
      // terms: newPurchaseValue?.terms,
      // termNumber: newPurchaseValue?.termNumber,
      products: purchaseProductCartData?.map((product) => {
        const {
          productId,
          quantity,
          purchasePrice,
          productName,
          subtotal,
          bag,
          qtyPerBag,
          purchaseProductId,
          taxTotal,
          remaningQty,
          PurchaseDiscount,
        } = product;
        const productObj = {
          ...(purchaseProductId && { purchaseProductId }),
          productId,
          productName,
          bag,
          qtyPerBag,
          quantity,
          purchasePrice: purchasePrice,
          subtotal,
          tax: +taxTotal,
          remaningQty: remaningQty,
          PurchaseDiscount: +PurchaseDiscount,
        };
        return productObj;
      }),
      payment: {
        subTotal: grandTotal,
        taxPercentage: 0,
        taxPrice: 0,
        shippingPrice: 0,
        total: grandTotal,
        discountPercentage: 0,
        discountPrice: discountTotal,
        grandTotal: grandTotal,
        ...(!id && { advanceAmount: newPurchaseValue?.advanceAmount }),
        ...(!id && { bankTransfer: bankTransferJson }),
        ...(!id && { cashTransfer: cashTransferJson }),
        ...(!id && { cashQuantity: [] }),
        ...(!id && {
          paymentMode:
            newPurchaseValue?.paymentMode === "bankTransfer"
              ? "bank-transfer"
              : newPurchaseValue?.paymentMode,
        }),
        ...(!id && {
          creditAmount: newPurchaseValue?.creditAmount || 0,
        }),
      },
    };
    const data = { payload, status, purchaseId: id };
    mutate(data);
  };

  const handleProductCode = async (e, productObj) => {
    if (e?.key === "Enter") {
      if (purchaseProductCartData.length > 0) {
        for (let i = 0; i < purchaseProductCartData.length; i++) {
          if (
            purchaseProductCartData[i]?.productCode == e.target.value ||
            purchaseProductCartData[i]?.productName == e.target.value ||
            purchaseProductCartData[i]?.productNumber == e.target.value
          ) {
            OpenNotificationComponent("Product already exist", "warning");
            return;
          }
        }
      }
      const payload = {
        searchedKeyWord: e.target.value,
      };
      const response = await dispatch(
        getProductData(payload, "", "", "others")
      );
      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          const responseData = response?.data?.data?.[0];
          dispatch(addProductToPurchaseCart(responseData));
        } else {
          OpenNotificationComponent(
            "Searched Product is not available",
            "warning"
          );
          dispatch(addProductToPurchaseCart(purchaseProductCartData));
        }
      }
    } else {
      const { name, value } = e.target;
      let productArr = [...purchaseProductCartData];
      productArr = productArr?.map((ele) => {
        if (ele?.productId === productObj?.productId) {
          return {
            ...ele,
            [name]: value,
          };
        } else {
          return ele;
        }
      });
      dispatch(addProductToPurchaseCart(productArr));
    }
  };

  const handleFocusSearchInput = () => {
    suggestionList.length > 0 && setShowSuggestionList(true);
  };

  const getSearchedProduct = (value) => {
    handleGetProduct(value);
  };

  const getProductSuggestions = async () => {
    await dispatch(getSuggestionProductNameForPurchase(searchValue));
    setSuggestionListLoading(false);
  };

  useDebounce(searchValue, getProductSuggestions);

  return (
    <NewPurchaseView
      {...{
        id,
        subTotal,
        error,
        discountTotal,
        productsTaxTotal,
        isSearchLoading,
        isPurchaseLoading,
        isModelOpen,
        isDueModelOpen,
        formFields,
        supplierList,
        newPurchaseValue,
        supplierDetails,
        editPurchaseHistory,
        searchValue,
        purchaseProductCartData,
        systemSettingDetails,
        grandTotal,
        isBtnDisable,
        isModelBtnDisabled,
        totalError,
        setTotalError,
        handleChange,
        handleSelectChange,
        handleSearchChange,
        handleProductQuantityChange,
        handleProductQuantitySelectChange,
        handleKeyDown,
        handleAddItem,
        handleRemoveItem,
        handleDeleteItem,
        handleClickSave,
        handleCloseModel,
        handleOpenDueModel,
        handleCloseDueModel,
        handlePayment,
        handleSelect,
        handleProductCode,
        handleUpdatePurchase,
        showSuggestionList,
        setShowSuggestionList,
        suggestionListLoading,
        handleFocusSearchInput,
        getSearchedProduct,
        suggestionList,
        listRef,
      }}
    />
  );
};

export default NewPurchaseContainer;
