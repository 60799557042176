export const COUNTRY_LIST_PHONE_CODE = [
  {
    name: "Afghanistan",
    code: "+93",
    isoCode: "AF",
    maxLength: 13,
  },
  {
    name: "Albania",
    code: "+355",
    isoCode: "AL",
    maxLength: 12,
  },
  {
    name: "Algeria",
    code: "+213",
    isoCode: "DZ",
    maxLength: 12,
  },
  {
    name: "American Samoa",
    code: "+1684",
    isoCode: "AS",
    maxLength: 12,
  },
  {
    name: "Andorra",
    code: "+376",
    isoCode: "AD",
    maxLength: 9,
  },
  {
    name: "Angola",
    code: "+244",
    isoCode: "AO",
    maxLength: 9,
  },
  {
    name: "Anguilla",
    code: "+1264",
    isoCode: "AI",
    maxLength: 12,
  },
  {
    name: "Antigua and Barbuda",
    code: "+1268",
    isoCode: "AG",
    maxLength: 11,
  },
  {
    name: "Argentina",
    code: "+54",
    isoCode: "AR",
    maxLength: 13,
  },
  {
    name: "Armenia",
    code: "+374",
    isoCode: "AM",
    maxLength: 12,
  },
  {
    name: "Aruba",
    code: "+297",
    isoCode: "AW",
    maxLength: 10,
  },
  {
    name: "Australia",
    code: "+61",
    isoCode: "AU",
    maxLength: 12,
  },
  {
    name: "Austria",
    code: "+43",
    isoCode: "AT",
    maxLength: 12,
  },
  {
    name: "Azerbaijan",
    code: "+994",
    isoCode: "AZ",
    maxLength: 12,
  },
  {
    name: "Bahamas",
    code: "+1242",
    isoCode: "BS",
    maxLength: 11,
  },
  {
    name: "Bahrain",
    code: "+973",
    isoCode: "BH",
    maxLength: 8,
  },
  {
    name: "Bangladesh",
    code: "+880",
    isoCode: "BD",
    maxLength: 12,
  },
  {
    name: "Barbados",
    code: "+1246",
    isoCode: "BB",
    maxLength: 11,
  },
  {
    name: "Belarus",
    code: "+375",
    isoCode: "BY",
    maxLength: 12,
  },
  {
    name: "Belgium",
    code: "+32",
    isoCode: "BE",
    maxLength: 11,
  },
  {
    name: "Belize",
    code: "+501",
    isoCode: "BZ",
    maxLength: 9,
  },
  {
    name: "Benin",
    code: "+229",
    isoCode: "BJ",
    maxLength: 10,
  },
  {
    name: "Bermuda",
    code: "+1441",
    isoCode: "BM",
    maxLength: 11,
  },
  {
    name: "Bhutan",
    code: "+975",
    isoCode: "BT",
    maxLength: 10,
  },
  {
    name: "Bolivia",
    code: "+591",
    isoCode: "BO",
    maxLength: 10,
  },
  {
    name: "Bosnia and Herzegovina",
    code: "+387",
    isoCode: "BA",
    maxLength: 10,
  },
  {
    name: "Botswana",
    code: "+267",
    isoCode: "BW",
    maxLength: 10,
  },
  {
    name: "Brazil",
    code: "+55",
    isoCode: "BR",
    maxLength: 11,
  },
  {
    name: "British Indian Ocean Territory",
    code: "+246",
    isoCode: "IO",
    maxLength: 11,
  },
  {
    name: "Brunei Darussalam",
    code: "+673",
    isoCode: "BN",
    maxLength: 10,
  },
  {
    name: "Bulgaria",
    code: "+359",
    isoCode: "BG",
    maxLength: 11,
  },
  {
    name: "Burkina Faso",
    code: "+226",
    isoCode: "BF",
    maxLength: 8,
  },
  {
    name: "Burundi",
    code: "+257",
    isoCode: "BI",
    maxLength: 9,
  },
  {
    name: "Cambodia",
    code: "+855",
    isoCode: "KH",
    maxLength: 9,
  },
  {
    name: "Cameroon",
    code: "+237",
    isoCode: "CM",
    maxLength: 9,
  },
  {
    name: "Canada",
    code: "+1",
    isoCode: "CA",
    maxLength: 10,
  },
  {
    name: "Cape Verde",
    code: "+238",
    isoCode: "CV",
    maxLength: 9,
  },
  {
    name: "Cayman Islands",
    code: "+1345",
    isoCode: "KY",
    maxLength: 11,
  },
  {
    name: "Central African Republic",
    code: "+236",
    isoCode: "CF",
    maxLength: 8,
  },
  {
    name: "Chad",
    code: "+235",
    isoCode: "TD",
    maxLength: 8,
  },
  {
    name: "Chile",
    code: "+56",
    isoCode: "CL",
    maxLength: 12,
  },
  {
    name: "China",
    code: "+86",
    isoCode: "CN",
    maxLength: 11,
  },
  {
    name: "Christmas Island",
    code: "+61",
    isoCode: "CX",
    maxLength: 15,
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "+61",
    isoCode: "CC",
    maxLength: 15,
  },
  {
    name: "Colombia",
    code: "+57",
    isoCode: "CO",
    maxLength: 10,
  },
  {
    name: "Comoros",
    code: "+269",
    isoCode: "KM",
    maxLength: 7,
  },
  {
    name: "Congo",
    code: "+242",
    isoCode: "CG",
    maxLength: 8,
  },
  {
    name: "Congo, The Democratic Republic of the",
    code: "+243",
    isoCode: "CD",
    maxLength: 10,
  },
  {
    name: "Cook Islands",
    code: "+682",
    isoCode: "CK",
    maxLength: 9,
  },
  {
    name: "Costa Rica",
    code: "+506",
    isoCode: "CR",
    maxLength: 10,
  },
  {
    name: "Cote d'Ivoire",
    code: "+225",
    isoCode: "CI",
    maxLength: 10,
  },
  {
    name: "Croatia",
    code: "+385",
    isoCode: "HR",
    maxLength: 13,
  },
  {
    name: "Cuba",
    code: "+53",
    isoCode: "CU",
    maxLength: 12,
  },
  {
    name: "Cyprus",
    code: "+357",
    isoCode: "CY",
    maxLength: 12,
  },
  {
    name: "Czech Republic",
    code: "+420",
    isoCode: "CZ",
    maxLength: 12,
  },
  {
    name: "Denmark",
    code: "+45",
    isoCode: "DK",
    maxLength: 8,
  },
  {
    name: "Djibouti",
    code: "+253",
    isoCode: "DJ",
    maxLength: 8,
  },
  {
    name: "Dominica",
    code: "+1767",
    isoCode: "DM",
    maxLength: 11,
  },
  {
    name: "Dominican Republic",
    code: "+1849",
    isoCode: "DO",
    maxLength: 11,
  },
  {
    name: "Ecuador",
    code: "+593",
    isoCode: "EC",
    maxLength: 10,
  },
  {
    name: "Egypt",
    code: "+20",
    isoCode: "EG",
    maxLength: 11,
  },
  {
    name: "El Salvador",
    code: "+503",
    isoCode: "SV",
    maxLength: 8,
  },
  {
    name: "Equatorial Guinea",
    code: "+240",
    isoCode: "GQ",
    maxLength: 8,
  },
  {
    name: "Eritrea",
    code: "+291",
    isoCode: "ER",
    maxLength: 8,
  },
  {
    name: "Estonia",
    code: "+372",
    isoCode: "EE",
    maxLength: 11,
  },
  {
    name: "Ethiopia",
    code: "+251",
    isoCode: "ET",
    maxLength: 9,
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "+500",
    isoCode: "FK",
    maxLength: 11,
  },
  {
    name: "Faroe Islands",
    code: "+298",
    isoCode: "FO",
    maxLength: 9,
  },
  {
    name: "Fiji",
    code: "+679",
    isoCode: "FJ",
    maxLength: 8,
  },
  {
    name: "Finland",
    code: "+358",
    isoCode: "FI",
    maxLength: 12,
  },
  {
    name: "France",
    code: "+33",
    isoCode: "FR",
    maxLength: 12,
  },
  {
    name: "French Guiana",
    code: "+594",
    isoCode: "GF",
    maxLength: 9,
  },
  {
    name: "French Polynesia",
    code: "+689",
    isoCode: "PF",
    maxLength: 11,
  },
  {
    name: "Gabon",
    code: "+241",
    isoCode: "GA",
    maxLength: 9,
  },
  {
    name: "Gambia",
    code: "+220",
    isoCode: "GM",
    maxLength: 8,
  },
  {
    name: "Georgia",
    code: "+995",
    isoCode: "GE",
    maxLength: 10,
  },
  {
    name: "Germany",
    code: "+49",
    isoCode: "DE",
    maxLength: 13,
  },
  {
    name: "Ghana",
    code: "+233",
    isoCode: "GH",
    maxLength: 10,
  },
  {
    name: "Gibraltar",
    code: "+350",
    isoCode: "GI",
    maxLength: 12,
  },
  {
    name: "Greece",
    code: "+30",
    isoCode: "GR",
    maxLength: 12,
  },
  {
    name: "Greenland",
    code: "+299",
    isoCode: "GL",
    maxLength: 8,
  },
  {
    name: "Grenada",
    code: "+1473",
    isoCode: "GD",
    maxLength: 11,
  },
  {
    name: "Guadeloupe",
    code: "+590",
    isoCode: "GP",
    maxLength: 11,
  },
  {
    name: "Guam",
    code: "+1671",
    isoCode: "GU",
    maxLength: 12,
  },
  {
    name: "Guatemala",
    code: "+502",
    isoCode: "GT",
    maxLength: 8,
  },
  {
    name: "Guernsey",
    code: "+44",
    isoCode: "GG",
    maxLength: 11,
  },
  {
    name: "Guinea",
    code: "+224",
    isoCode: "GN",
    maxLength: 9,
  },
  {
    name: "Guinea-Bissau",
    code: "+245",
    isoCode: "GW",
    maxLength: 8,
  },
  {
    name: "Guyana",
    code: "+592",
    isoCode: "GY",
    maxLength: 11,
  },
  {
    name: "Haiti",
    code: "+509",
    isoCode: "HT",
    maxLength: 10,
  },
  {
    name: "Holy See (Vatican City State)",
    code: "+379",
    isoCode: "VA",
    maxLength: 12,
  },
  {
    name: "Honduras",
    code: "+504",
    isoCode: "HN",
    maxLength: 8,
  },
  {
    name: "Hong Kong",
    code: "+852",
    isoCode: "HK",
    maxLength: 8,
  },
  {
    name: "Hungary",
    code: "+36",
    isoCode: "HU",
    maxLength: 11,
  },
  {
    name: "Iceland",
    code: "+354",
    isoCode: "IS",
    maxLength: 9,
  },
  {
    name: "India",
    code: "+91",
    isoCode: "IN",
    maxLength: 10,
  },
  {
    name: "Indonesia",
    code: "+62",
    isoCode: "ID",
    maxLength: 11,
  },
  {
    name: "Iran, Islamic Republic of",
    code: "+98",
    isoCode: "IR",
    maxLength: 11,
  },
  {
    name: "Iraq",
    code: "+964",
    isoCode: "IQ",
    maxLength: 11,
  },
  {
    name: "Ireland",
    code: "+353",
    isoCode: "IE",
    maxLength: 12,
  },
  {
    name: "Isle of Man",
    code: "+44",
    isoCode: "IM",
    maxLength: 11,
  },
  {
    name: "Israel",
    code: "+972",
    isoCode: "IL",
    maxLength: 12,
  },
  {
    name: "Italy",
    code: "+39",
    isoCode: "IT",
    maxLength: 12,
  },
  {
    name: "Jamaica",
    code: "+1876",
    isoCode: "JM",
    maxLength: 12,
  },
  {
    name: "Japan",
    code: "+81",
    isoCode: "JP",
    maxLength: 11,
  },
  {
    name: "Jersey",
    code: "+44",
    isoCode: "JE",
    maxLength: 11,
  },
  {
    name: "Jordan",
    code: "+962",
    isoCode: "JO",
    maxLength: 11,
  },
  {
    name: "Kazakhstan",
    code: "+7",
    isoCode: "KZ",
    maxLength: 11,
  },
  {
    name: "Kenya",
    code: "+254",
    isoCode: "KE",
    maxLength: 10,
  },
  {
    name: "Kiribati",
    code: "+686",
    isoCode: "KI",
    maxLength: 8,
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: "+850",
    isoCode: "KP",
    maxLength: 10,
  },
  {
    name: "Korea, Republic of",
    code: "+82",
    isoCode: "KR",
    maxLength: 11,
  },
  {
    name: "Kuwait",
    code: "+965",
    isoCode: "KW",
    maxLength: 10,
  },
  {
    name: "Kyrgyzstan",
    code: "+996",
    isoCode: "KG",
    maxLength: 12,
  },
  {
    name: "Lao People's Democratic Republic",
    code: "+856",
    isoCode: "LA",
    maxLength: 9,
  },
  {
    name: "Latvia",
    code: "+371",
    isoCode: "LV",
    maxLength: 11,
  },
  {
    name: "Lebanon",
    code: "+961",
    isoCode: "LB",
    maxLength: 10,
  },
  {
    name: "Lesotho",
    code: "+266",
    isoCode: "LS",
    maxLength: 9,
  },
  {
    name: "Liberia",
    code: "+231",
    isoCode: "LR",
    maxLength: 9,
  },
  {
    name: "Libyan Arab Jamahiriya",
    code: "+218",
    isoCode: "LY",
    maxLength: 11,
  },
  {
    name: "Liechtenstein",
    code: "+423",
    isoCode: "LI",
    maxLength: 12,
  },
  {
    name: "Lithuania",
    code: "+370",
    isoCode: "LT",
    maxLength: 12,
  },
  {
    name: "Luxembourg",
    code: "+352",
    isoCode: "LU",
    maxLength: 12,
  },
  {
    name: "Macao",
    code: "+853",
    isoCode: "MO",
    maxLength: 9,
  },
  {
    name: "North Macedonia",
    code: "+389",
    isoCode: "MK",
    maxLength: 10,
  },
  {
    name: "Madagascar",
    code: "+261",
    isoCode: "MG",
    maxLength: 10,
  },
  {
    name: "Malawi",
    code: "+265",
    isoCode: "MW",
    maxLength: 10,
  },
  {
    name: "Malaysia",
    code: "+60",
    isoCode: "MY",
    maxLength: 11,
  },
  {
    name: "Maldives",
    code: "+960",
    isoCode: "MV",
    maxLength: 10,
  },
  {
    name: "Mali",
    code: "+223",
    isoCode: "ML",
    maxLength: 9,
  },
  {
    name: "Malta",
    code: "+356",
    isoCode: "MT",
    maxLength: 11,
  },
  {
    name: "Marshall Islands",
    code: "+692",
    isoCode: "MH",
    maxLength: 8,
  },
  {
    name: "Martinique",
    code: "+596",
    isoCode: "MQ",
    maxLength: 11,
  },
  {
    name: "Mauritania",
    code: "+222",
    isoCode: "MR",
    maxLength: 9,
  },
  {
    name: "Mauritius",
    code: "+230",
    isoCode: "MU",
    maxLength: 9,
  },
  {
    name: "Mayotte",
    code: "+262",
    isoCode: "YT",
    maxLength: 11,
  },
  {
    name: "Mexico",
    code: "+52",
    isoCode: "MX",
    maxLength: 13,
  },
  {
    name: "Micronesia, Federated States of",
    code: "+691",
    isoCode: "FM",
    maxLength: 8,
  },
  {
    name: "Moldova, Republic of",
    code: "+373",
    isoCode: "MD",
    maxLength: 12,
  },
  {
    name: "Monaco",
    code: "+377",
    isoCode: "MC",
    maxLength: 12,
  },
  {
    name: "Mongolia",
    code: "+976",
    isoCode: "MN",
    maxLength: 11,
  },
  {
    name: "Montenegro",
    code: "+382",
    isoCode: "ME",
    maxLength: 12,
  },
  {
    name: "Montserrat",
    code: "+1664",
    isoCode: "MS",
    maxLength: 11,
  },
  {
    name: "Morocco",
    code: "+212",
    isoCode: "MA",
    maxLength: 12,
  },
  {
    name: "Mozambique",
    code: "+258",
    isoCode: "MZ",
    maxLength: 11,
  },
  {
    name: "Myanmar",
    code: "+95",
    isoCode: "MM",
    maxLength: 11,
  },
  {
    name: "Namibia",
    code: "+264",
    isoCode: "NA",
    maxLength: 10,
  },
  {
    name: "Nauru",
    code: "+674",
    isoCode: "NR",
    maxLength: 8,
  },
  {
    name: "Nepal",
    code: "+977",
    isoCode: "NP",
    maxLength: 12,
  },
  {
    name: "Netherlands",
    code: "+31",
    isoCode: "NL",
    maxLength: 12,
  },
  {
    name: "Netherlands Antilles",
    code: "+599",
    isoCode: "AN",
    maxLength: 12,
  },
  {
    name: "New Caledonia",
    code: "+687",
    isoCode: "NC",
    maxLength: 11,
  },
  {
    name: "New Zealand",
    code: "+64",
    isoCode: "NZ",
    maxLength: 11,
  },
  {
    name: "Nicaragua",
    code: "+505",
    isoCode: "NI",
    maxLength: 8,
  },
  {
    name: "Niger",
    code: "+227",
    isoCode: "NE",
    maxLength: 9,
  },
  {
    name: "Nigeria",
    code: "+234",
    isoCode: "NG",
    maxLength: 11,
  },
  {
    name: "Niue",
    code: "+683",
    isoCode: "NU",
    maxLength: 8,
  },
  {
    name: "Norfolk Island",
    code: "+672",
    isoCode: "NF",
    maxLength: 15,
  },
  {
    name: "Northern Mariana Islands",
    code: "+1670",
    isoCode: "MP",
    maxLength: 12,
  },
  {
    name: "Norway",
    code: "+47",
    isoCode: "NO",
    maxLength: 10,
  },
  {
    name: "Oman",
    code: "+968",
    isoCode: "OM",
    maxLength: 10,
  },
  {
    name: "Pakistan",
    code: "+92",
    isoCode: "PK",
    maxLength: 12,
  },
  {
    name: "Palau",
    code: "+680",
    isoCode: "PW",
    maxLength: 8,
  },
  {
    name: "Palestinian Territory, Occupied",
    code: "+970",
    isoCode: "PS",
    maxLength: 12,
  },
  {
    name: "Panama",
    code: "+507",
    isoCode: "PA",
    maxLength: 10,
  },
  {
    name: "Papua New Guinea",
    code: "+675",
    isoCode: "PG",
    maxLength: 10,
  },
  {
    name: "Paraguay",
    code: "+595",
    isoCode: "PY",
    maxLength: 11,
  },
  {
    name: "Peru",
    code: "+51",
    isoCode: "PE",
    maxLength: 11,
  },
  {
    name: "Philippines",
    code: "+63",
    isoCode: "PH",
    maxLength: 13,
  },
  {
    name: "Pitcairn",
    code: "+872",
    isoCode: "PN",
    maxLength: 15,
  },
  {
    name: "Poland",
    code: "+48",
    isoCode: "PL",
    maxLength: 11,
  },
  {
    name: "Portugal",
    code: "+351",
    isoCode: "PT",
    maxLength: 12,
  },
  {
    name: "Puerto Rico",
    code: "+1939",
    isoCode: "PR",
    maxLength: 13,
  },
  {
    name: "Qatar",
    code: "+974",
    isoCode: "QA",
    maxLength: 11,
  },
  {
    name: "Reunion",
    code: "+262",
    isoCode: "RE",
    maxLength: 11,
  },
  {
    name: "Romania",
    code: "+40",
    isoCode: "RO",
    maxLength: 12,
  },
  {
    name: "Russian Federation",
    code: "+7",
    isoCode: "RU",
    maxLength: 11,
  },
  {
    name: "Rwanda",
    code: "+250",
    isoCode: "RW",
    maxLength: 9,
  },
  {
    name: "Saint Helena",
    code: "+290",
    isoCode: "SH",
    maxLength: 11,
  },
  {
    name: "Saint Kitts and Nevis",
    code: "+1869",
    isoCode: "KN",
    maxLength: 11,
  },
  {
    name: "Saint Lucia",
    code: "+1758",
    isoCode: "LC",
    maxLength: 11,
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "+508",
    isoCode: "PM",
    maxLength: 12,
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "+1784",
    isoCode: "VC",
    maxLength: 11,
  },
  {
    name: "Samoa",
    code: "+685",
    isoCode: "WS",
    maxLength: 8,
  },
  {
    name: "San Marino",
    code: "+378",
    isoCode: "SM",
    maxLength: 12,
  },
  {
    name: "Sao Tome and Principe",
    code: "+239",
    isoCode: "ST",
    maxLength: 9,
  },
  {
    name: "Saudi Arabia",
    code: "+966",
    isoCode: "SA",
    maxLength: 12,
  },
  {
    name: "Senegal",
    code: "+221",
    isoCode: "SN",
    maxLength: 9,
  },
  {
    name: "Serbia",
    code: "+381",
    isoCode: "RS",
    maxLength: 12,
  },
  {
    name: "Seychelles",
    code: "+248",
    isoCode: "SC",
    maxLength: 9,
  },
  {
    name: "Sierra Leone",
    code: "+232",
    isoCode: "SL",
    maxLength: 9,
  },
  {
    name: "Singapore",
    code: "+65",
    isoCode: "SG",
    maxLength: 8,
  },
  {
    name: "Slovakia",
    code: "+421",
    isoCode: "SK",
    maxLength: 12,
  },
  {
    name: "Slovenia",
    code: "+386",
    isoCode: "SI",
    maxLength: 12,
  },
  {
    name: "Solomon Islands",
    code: "+677",
    isoCode: "SB",
    maxLength: 8,
  },
  {
    name: "Somalia",
    code: "+252",
    isoCode: "SO",
    maxLength: 10,
  },
  {
    name: "South Africa",
    code: "+27",
    isoCode: "ZA",
    maxLength: 12,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "+500",
    isoCode: "GS",
    maxLength: 11,
  },
  {
    name: "Spain",
    code: "+34",
    isoCode: "ES",
    maxLength: 12,
  },
  {
    name: "Sri Lanka",
    code: "+94",
    isoCode: "LK",
    maxLength: 12,
  },
  {
    name: "Sudan",
    code: "+249",
    isoCode: "SD",
    maxLength: 11,
  },
  {
    name: "Suriname",
    code: "+597",
    isoCode: "SR",
    maxLength: 10,
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "+47",
    isoCode: "SJ",
    maxLength: 13,
  },
  {
    name: "Swaziland",
    code: "+268",
    isoCode: "SZ",
    maxLength: 10,
  },
  {
    name: "Sweden",
    code: "+46",
    isoCode: "SE",
    maxLength: 12,
  },
  {
    name: "Switzerland",
    code: "+41",
    isoCode: "CH",
    maxLength: 13,
  },
  {
    name: "Syrian Arab Republic",
    code: "+963",
    isoCode: "SY",
    maxLength: 12,
  },
  {
    name: "Taiwan, Province of China",
    code: "+886",
    isoCode: "TW",
    maxLength: 12,
  },
  {
    name: "Tajikistan",
    code: "+992",
    isoCode: "TJ",
    maxLength: 11,
  },
  {
    name: "Tanzania, United Republic of",
    code: "+255",
    isoCode: "TZ",
    maxLength: 11,
  },
  {
    name: "Thailand",
    code: "+66",
    isoCode: "TH",
    maxLength: 11,
  },
  {
    name: "Timor-Leste",
    code: "+670",
    isoCode: "TL",
    maxLength: 10,
  },
  {
    name: "Togo",
    code: "+228",
    isoCode: "TG",
    maxLength: 9,
  },
  {
    name: "Tokelau",
    code: "+690",
    isoCode: "TK",
    maxLength: 8,
  },
  {
    name: "Tonga",
    code: "+676",
    isoCode: "TO",
    maxLength: 8,
  },
  {
    name: "Trinidad and Tobago",
    code: "+1868",
    isoCode: "TT",
    maxLength: 11,
  },
  {
    name: "Tunisia",
    code: "+216",
    isoCode: "TN",
    maxLength: 11,
  },
  {
    name: "Turkey",
    code: "+90",
    isoCode: "TR",
    maxLength: 11,
  },
  {
    name: "Turkmenistan",
    code: "+993",
    isoCode: "TM",
    maxLength: 11,
  },
  {
    name: "Turks and Caicos Islands",
    code: "+1649",
    isoCode: "TC",
    maxLength: 11,
  },
  {
    name: "Tuvalu",
    code: "+688",
    isoCode: "TV",
    maxLength: 8,
  },
  {
    name: "Uganda",
    code: "+256",
    isoCode: "UG",
    maxLength: 10,
  },
  {
    name: "Ukraine",
    code: "+380",
    isoCode: "UA",
    maxLength: 12,
  },
  {
    name: "United Arab Emirates",
    code: "+971",
    isoCode: "AE",
    maxLength: 12,
  },
  {
    name: "United Kingdom",
    code: "+44",
    isoCode: "GB",
    maxLength: 12,
  },
  {
    name: "United States",
    code: "+1",
    isoCode: "US",
    maxLength: 13,
  },
  {
    name: "Uruguay",
    code: "+598",
    isoCode: "UY",
    maxLength: 11,
  },
  {
    name: "Uzbekistan",
    code: "+998",
    isoCode: "UZ",
    maxLength: 11,
  },
  {
    name: "Vanuatu",
    code: "+678",
    isoCode: "VU",
    maxLength: 8,
  },
  {
    name: "Venezuela",
    code: "+58",
    isoCode: "VE",
    maxLength: 11,
  },
  {
    name: "Vietnam",
    code: "+84",
    isoCode: "VN",
    maxLength: 11,
  },
  {
    name: "Virgin Islands, British",
    code: "+1284",
    isoCode: "VG",
    maxLength: 13,
  },
  {
    name: "Virgin Islands, U.S.",
    code: "+1",
    isoCode: "VI",
    maxLength: 13,
  },
  {
    name: "Wallis and Futuna",
    code: "+681",
    isoCode: "WF",
    maxLength: 8,
  },
  {
    name: "Western Sahara",
    code: "+212",
    isoCode: "EH",
    maxLength: 12,
  },
  {
    name: "Yemen",
    code: "+967",
    isoCode: "YE",
    maxLength: 12,
  },
  {
    name: "Zambia",
    code: "+260",
    isoCode: "ZM",
    maxLength: 10,
  },
  {
    name: "Zimbabwe",
    code: "+263",
    isoCode: "ZW",
    maxLength: 10,
  },
];
