import React from "react";

const PriceCalculationCard = ({
  subTotalPrice = 0,
  shippingPrice = 0,
  discountPrice = 0,
  systemSettingDetails,
  productsTaxTotal,
  // discountTotal,
  // pound,
  // mixMatchDiscount,
}) => {
  return (
    <div className="bill-list-wrap">
      <div className="order-bill">
        <div className="bill-main">
          <p className="order-list-bill">Sub Total</p>
          <p className="order-list-bill">
            {systemSettingDetails?.currency}
            {subTotalPrice || 0}
          </p>
        </div>
        <div className="bill-main">
          <p className="order-list-bill">Tax (%)</p>
          <p className="order-list-bill">{productsTaxTotal || 0}</p>
        </div>
        {/* <div className="bill-main">
          <p className="order-list-bill">Points </p>
          <p className="order-list-bill">{pound || 0}</p>
        </div>
        <div className="bill-main">
          <p className="order-list-bill">Discount (%)</p>
          <p className="order-list-bill">{discountTotal || 0}</p>
        </div>
        <div className="bill-main">
          <p className="order-list-bill">Mix Match / Bundle Item Discount</p>
          <p className="order-list-bill">
            {systemSettingDetails?.currency} {mixMatchDiscount.toFixed(2) || 0}
          </p>
        </div> */}
        {false && (
          <>
            <div className="bill-main">
              <p className="order-list-bill">Shipping</p>
              <p className="order-list-bill">
                {systemSettingDetails?.currency}
                {shippingPrice | 0}
              </p>
            </div>
            <div className="bill-main">
              <p className="order-list-bill">Grand Total</p>
              <p className="order-list-bill">
                {systemSettingDetails?.currency}
                {(
                  parseFloat(productsTaxTotal) +
                  parseFloat(shippingPrice) +
                  parseFloat(subTotalPrice) +
                  parseFloat(discountPrice)
                ).toFixed(2)}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PriceCalculationCard;
